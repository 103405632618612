<template>
   <div>
     <section id="page-title">
       <div class="container clearfix">
         <h1>Lejer information<a id="logout" class="hidden-sm hidden-xs" href="">Log af</a></h1>
       </div>
     </section>
     <section id="content">
       <div class="content-wrap">
         <div class="container clearfix">
           <div class="mappe row">
             <div class="col-md-6 col-xs-12">
               <p class="h2 nobottommargin notopmargin"></p>
               <p>
                 Sidst opdateret den: {{ theUser.loginName }}
               </p>              
               <p class="nobottommargin headline">Særlig besked:<span class="pull-right">Dato: </span></p>
               <div class="grey-box">

                 <b>Vedhæftet fil:</b>&nbsp;&nbsp;<a href=""></a>
               </div>

               <p class="h2 nobottommargin topmargin-xs">Indholdsfortegnelse</p>
               <p class="bottommargin-xs">Under hvert menupunkt ligger der en PDF, som beskriver forhold og regler omkring ejendommen og dit eget lejemål samt diverse bruger- og produktmanualer.</p>
               <table>
                 <tr>
                   <th>Fil</th>
                   <th>Sidst opdateret</th>
                 </tr>
                 <tr>
                   <td><a href="" target="_blank"></a></td>
                   <td></td>
                 </tr>
               </table>
             </div>
             <div class="col-md-6 hidden-sm hidden-xs">
               <img class="pull-right" src="">
             </div>
           </div>
         </div>
         </div>
         </section>
       </div>
</template>

<script>
  export default {
  name: 'Residentinfo',
  props: ['theUser'],
  data () {

  }
  }
  
  
</script>

<style>
</style>
