<template>

<header id="header" :class="'full-header transparent-header ' + stickyheader + ' ' + pagecontext +' ' + fullSizeClass + this.$route.name">
  <aside v-if="popupCallToAction" class="hidden">
    <div class="sidebar-wrapper">
      <a class="box">
	<div class="text"><span>{{ popupCallToActionHeadline }}</span></div>
	<div :class="'icon '+ active" v-on:click="contactformpopup">
          <i class="far fa-arrow-alt-circle-up"></i>
          <div class="close"><i class="far fa-arrow-alt-circle-down"></i></div>
	</div>
      </a>
      <div class="formular">
	<div class="wrapper">
          <img :src="popupCallToActionImage" :alt="popupCallToActionImageTitle">
          <p v-html="popupCallToActionText"></p>
          <Contactform v-if="siteId == 1" v-bind:gaevent="'mobile'"/>
          <ContactformEnglish v-if="siteId == 2" v-bind:gaevent="'mobile'"/>          
	</div>
      </div>
    </div>
  </aside>
  <a v-if="siteId ==1" href="/kontakt">
      <aside v-show="contactlabel" class="stickey-label hidden-xs" style="display:none;">
        <span class="text" v-if="siteId == 1">Udfyld kontaktformular</span>
      </aside>
  </a>
  <a v-if="siteId ==2" href="/contact">
      <aside v-show="contactlabel" class="stickey-label hidden-xs" style="display:none;">
        <span class="text" v-if="siteId == 2">Contact us</span>
      </aside>
  </a>
  

  <div id="mobile-dark-fix" class="not-dark">
    <div id="header-wrap">

      <div class="container">
        <div class="languageselecter">
          <span><a href="/da"><img src="/assets/images/da-flag.png"></a></span>
          <span><a href="/en"><img src="/assets/images/en-flag.png"></a></span>
          <span><a href="/de"><img src="/assets/images/de-flag.png"></a></span>
        </div>
          
        <div class="phone">
          <a href="tel:+4560391600"><i class="fa fas-phone"></i><span>+45 6039 1600</span></a>
        </div>          
      </div>
      
      <div class="container clearfix">
      
        <div v-if="whitelogo">
          <div id="logo" v-for="global in globalSet" :key="global">
            <router-link :to="'/' + $i18n.locale">
            <div class="standard-logo" :alt="global.logo_alt" v-for="logo in global.big_logo" v-bind:key="logo">
              <img :src="logo.url" :alt="global.logo_alt">
            </div>
            </router-link>
          </div>
        </div>
        
        <div v-if="colorlogo">
          <div id="logo" v-for="global in globalSet" :key="global">
            <router-link :to="'/' + $i18n.locale" class="standard-logo " :alt="global.logo_alt" v-for="logo in global.small_logo" v-bind:key="logo">
              <img :src="logo.url" :alt="global.logo_alt">
            </router-link>
          </div>
        </div>

        <div id="primary-menu-trigger" v-if="siteId === 1" v-on:click="openhamburger"><i :class="fabar + ' menu-icon fas'"></i></div>
        
        <nav id="primary-menu" class="not-dark" v-if="hbmenu">
          <ul v-show="hbmenu">
            <li class="clearfix">
              <router-link :to="'/' + $i18n.locale + $t('Menupath for rental') ">
                <div>{{ $t('Menutext for rental') }}</div>
              </router-link>
            </li>            
            <li class="clearfix">
              <router-link :to="'/' + $i18n.locale + $t('Menupath for tenant')">
                <div>{{ $t('Menutext for tenant') }}</div>
              </router-link>
            </li>
            <li class="clearfix">
              <router-link :to="'/' + $i18n.locale + $t('Menupath for tenant contact')">
                <div>{{ $t('Menutext for tenant contact') }}</div>
              </router-link>
            </li>
            <li class="clearfix">
              <router-link :to="'/' + $i18n.locale + $t('Menupath for information')">
                <div>{{ $t('Menutext for information') }}</div>
              </router-link>
            </li>
            <li class="clearfix">
              <router-link :to="'/' + $i18n.locale + $t('Menupath for about industribo')">
                <div>{{ $t('Menutext for about industribo') }}</div>
              </router-link>
            </li>            
            <li class="clearfix">
              <router-link :to="'/' + $i18n.locale + $t('Menupath for tenant login')">
                <div>{{ $t('Menutext for tenant login') }}</div>
              </router-link>
            </li>
            <li class="clearfix">
              <router-link :to="'/' + $i18n.locale + $t('Menupath for interestlist')">
                <div>{{ $t('Menutext for interestlist') }}</div>
              </router-link>
            </li>
            <li class="clearfix">
              <router-link :to="'/' + $i18n.locale + $t('Menupath for contact us')">
                <div>{{ $t('Menutext for contact us') }}</div>
              </router-link>
            </li>            
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>  
</template>

<script>
import Contactform from './Contactform'
import ContactformEnglish from './ContactformEnglish'
import { siteId } from '../generalSettings.js'


  export default {
    name: 'header',
    props: ['globalSet', 'fullsizepage'],
    data() {
    return {
        siteId: siteId,
        clickCount: 0,
        active: '',
        stickyheader:'',
        show: false,
        colorlogo:'',
        whitelogo:'',
        hbmenu: false,
        fabar: "fa-bars",
        popupCallToAction: false,
        popupCallToActionHeadline: '',
        popupCallToActionText: '',
        popupCallToActionImage: '',
        popupCallToActionImageTitle: '',
        contactlabel: false,
        contactformActive: false,
        fullSizeClass:'',
        pagecontext: ''
      }
    },
    components: {
        Contactform,
        ContactformEnglish
    },
    created() {
        window.addEventListener('resize', this.responsiveHandler)
    },
    destroyed() {
        window.removeEventListener('resize', this.responsiveHandler)
    },
    watch: {
       globalSet: function() {
          this.popupCallToAction = this.globalSet[0].popupCallToAction
          this.popupCallToActionHeadline = this.globalSet[0].popupCallToActionHeadline
          this.popupCallToActionText = this.globalSet[0].popupCallToActionText
          this.popupCallToActionImage = this.globalSet[0].popupCallToActionImage[0].url
          this.popupCallTOActionImageTitle = this.globalSet[0].popupCallToActionImage[0].title
       },
       $route () {
           this.responsiveHandler()
           if(window.location.pathname == '/') {
               this.pagecontext = 'index'
           } else {
               this.pagecontext = ''
           }
       }
    },
    mounted() {
        this.responsiveHandler()

        setTimeout(() => {
            this.contactlabel = true
        }, 2000)

        if(window.location.pathname == '/') {
            this.pagecontext = 'index'
        } else {
            this.pagecontext = ''
        }
    },
    methods: {
       dropdown() {
            if(window.innerWidth > 991) {
                this.show = true
            }
       },
       closedropdown() {
           if(window.innerWidth > 991) {
               this.show = false
           }
       },
       triggerdropdown: function(event) {
          if(window.innerWidth < 991) {
              this.clickCount++
              if(this.clickCount == 1) {
                  this.show = true
                  event.preventDefault()
              } else {
                  this.clickCount = 0                              
              }
         }
       },
       openhamburger: function() {
           this.hbmenu = !this.hbmenu;
           if(this.hbmenu) {
               this.fabar = 'fa-times';
           } else {
               this.fabar = 'fa-bars';
           }
       },
       contactformpopup: function() {
          this.contactformActive = !this.contactformActive
          if(this.contactformActive) {
              this.active = 'active'
          } else {
              this.active = ''
          }
       },
       responsiveHandler () {
           this.hbmenu = false
           this.fabar = 'fa-bars';

           if(this.fullsizepage) {
               this.fullSizeClass = 'fullsize'
           } else {
               this.fullSizeClass = ''
           }
  
           if(window.innerWidth < 991) {
              this.hbmenu = false
              this.fabar = 'fa-bars'
              this.show = false
              this.clickCount = 0                    
              this.fullSizeClass = ''            
              this.colorlogo = true
              this.whitelogo = false                   
           } else {
              this.nonResponsiveMode()
              this.hbmenu = false
           }
       },
       nonResponsiveMode () {                                  
          if(this.fullsizepage) {                        
              this.colorlogo = false
              this.whitelogo = true
          } else {
              this.colorlogo = true
              this.whitelogo = false
          }
          window.document.onscroll = () => {
             let header = document.getElementById('header');
             if(window.scrollY > header.offsetTop){
                this.stickyheader = 'sticky-header'
                this.colorlogo = true
                this.whitelogo = false
             } else {
                this.stickyheader = ''
                    if(this.fullsizepage) {
                        this.whitelogo = true
                        this.colorlogo = false
                    } else {
                        this.whitelogo = false
                        this.colorlogo = true
                    }
             }
         }
       }
    }
  }

</script>

<style>
</style>
