<template>
  <section id="content">
    <div class="content-wrap">
         <div v-if="contentrows.content_sub_title" class="container plain-content clearfix">
           <div class="col_full">
             <p class="h2">{{ contentrows.content_sub_title }}</p>
             <div v-html="contentrows.text_content"></div>
           </div>
         </div>
         <div v-if="contentrows.content_content_grid" ref="contentcontainer" class="container clearfix">
          <div class="row">
           <div v-for="row in contentrows.content_content_grid" :key="row" :class="row.breakColumn? row.column + ' breakthis' : row.column + ' dontbreakthis'">
               <div v-if="row.medie_position === 'right'"
                    :class="getopsitewith(row.medie_width) + ' topmargin-sm bottommargin-sm'">
                 <div class="heading-block">
                   <h2 v-if="row.headline">{{ row.headline }}</h2>
                   
                   <div v-if="row.medie_position !== 'none' || row.medie_position !== 'fullwidth'">
                     <div class="hidden-sm hidden-md hidden-lg">
                       <div v-for="image in row.billede" :key="image">                       
                         <img v-if="image.url && row.medie_type === 'image'"
                              class="fadeInRightBig animated"
                              data-animate="fadeInRightBig"
                              :alt="image.title"
                              >
                       </div>
                       
                       <div v-if="row.medie_type === 'video'">
                         <div class="embed-container"
                              v-if="row.video_type === '' || row.video_type === 'youtube'">
                           <iframe :src="'https://www.youtube.com/embed/'+ row.youtube_video  +'?rel=0'"
                                   frameborder="0"
                                   allowfullscreen></iframe>
                         </div>
                         
                         <div class='embed-container'
                              v-if="row.video_type === 'vimeo'">
                           <iframe :src="'https://player.vimeo.com/video/' + row.vimeo_video"
                                   frameborder='0'
                                   webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                         </div>
                       </div>
                       <div v-if="row.media_type === 'form'">
                         {{ row.form }}
                       </div>
                       <router-link v-if="row.url_link && row.medie_position === 'right'"
                          :to="'/' + $i18n.locale + row.url_link"
                          :target="row.linkTarget"
                          class="button button-border                                 
                                 button-rounded hidden-lg 
                                 hidden-md button-large 
                                 noleftmargin topmargin-sm">{{ row.link_text }}</router-link>
                       
                     </div>                   
                     <span v-html="row.text"></span>
                     <router-link v-if="row.url_link"
                        :to="'/' + $i18n.locale + row.url_link"
                        :target="row.linkTarget"
                        class="button button-border
                               hidden-sm hidden-xs button-rounded
                               button-large noleftmargin topmargin-sm">
                       {{ row.link_text }}
                     </router-link>
                   </div>
                 </div>
               </div>
               
               

               <div v-if="row.medie_position !== 'none' && row.medie_position !== 'fullwidth'" :class="mediapos(row.text,row.medie_width,row.medie_position) + ' topmargin-sm bottommargin'">
                   <div v-for="image in row.billede" :key="image">
                     <img v-if="image.url && row.medie_type === 'image'"
                          :alt="image.title"
                          v-lazy="image.url">
                   </div>                 
                   <div v-if="row.medie_type === 'video'">
                     <div class="embed-container"
                          v-if="row.video_type === '' || row.video_type === 'youtube'">
                       <iframe :src="'https://www.youtube.com/embed/'+ row.youtube_video  +'?rel=0'"
                               frameborder="0"
                               allowfullscreen></iframe>
                     </div>
                     
                     <div class='embed-container'
                          v-if="row.video_type === 'vimeo'">
                       <iframe :src="'https://player.vimeo.com/video/' + row.vimeo_video"
                               frameborder='0'
                               webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                     </div>
                   </div>
                   <div v-if="row.media_type === 'form'">
                     {{ row.form }}
                   </div>
                   <router-link v-if="row.url_link && row.medie_position === 'right'"
                      :to="'/' + $i18n.locale + row.url_link"
                      :target="row.linkTarget"
                      class="button button-border hidden-sm 
                             hidden-xs button-rounded button-large 
                             noleftmargin topmargin-sm">
                     {{ row.link_text }}
                   </router-link>                   
               </div>
               
               <div v-if="row.medie_position === 'fullwidth'">
                 <div v-for="image in row.billede" :key="image">
                   <img v-if="image.url && row.medie_type === 'image'"
                        data-animate="fadeInRightBig"
                        :alt="image.title"
                        v-lazy="image.url">
                 </div>
                 <div v-if="row.medie_type === 'video'">
                   <div class="embed-container"
                        v-if="row.video_type === '' || row.video_type === 'youtube'">
                     <iframe :src="'https://www.youtube.com/embed/'+ row.youtube_video  +'?rel=0'"
                             frameborder="0"
                             allowfullscreen></iframe>
                   </div>
                   
                   <div class='embed-container'
                        v-if="row.video_type === 'vimeo'">
                     <iframe :src="'https://player.vimeo.com/video/' + row.vimeo_video"
                             frameborder='0'
                             webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                   </div>
                 </div>
                 <div v-if="row.medie_type === 'form' && row.form">
                   <Contactform />
                 </div>

                 <router-link v-if="row.url_link && row.medie_position === 'right'"
                    :to="'/' + $i18n.locale + row.url_link"
                    :target="row.linkTarget"
                    class="button button-border hidden-sm 
                           hidden-xs button-rounded button-large 
                           noleftmargin topmargin-sm">
                   {{ row.link_text }}
                 </router-link>
               </div>
               
               <div v-if="row.medie_position === 'left'"
                    :class="getopsitewith(row.medie_width) + ' topmargin-sm bottommargin-sm col_last'">
                 <div class="heading-block">
                   <h2 v-if="row.headline">{{ row.headline }}</h2>
                   
                   <div v-if="row.medie_position !== 'none' || row.medie_position !== 'fullwidth'">
                     <div class="hidden-sm hidden-md hidden-lg">
                       <div v-for="image in row.billede" :key="image">                     
                         <img v-if="image.url && row.medie_type === 'image'"
                              data-animate="fadeInRightBig"                              
                              :alt="image.title"
                              v-lazy="image">
                       </div>
                       <div v-if="row.medie_type === 'video'">
                         <div class="embed-container"
                              v-if="row.video_type === '' || row.video_type === 'youtube'">
                           <iframe :src="'https://www.youtube.com/embed/'+ row.youtube_video  +'?rel=0'"
                                   frameborder="0"
                                   allowfullscreen></iframe>
                         </div>
                         
                         <div class='embed-container'
                              v-if="row.video_type === 'vimeo'">
                           <iframe :src="'https://player.vimeo.com/video/' + row.vimeo_video"
                                   frameborder='0'
                                   webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                         </div>
                       </div>
                       <div v-if="row.media_type === 'form'">
                         {{ row.form }}
                       </div>
                       <router-link v-if="row.url_link && row.medie_position === 'right'"
                          :to="'/' + $i18n.locale + row.url_link"
                          :target="row.linkTarget"
                          class="button button-border
                                 button-rounded hidden-lg 
                                 hidden-md button-large 
                                 noleftmargin topmargin-sm">{{ row.link_text }}</router-link>
                     </div>
                     <span v-html="row.text"></span>
                     <router-link v-if="row.url_link"
                        :to="'/' + $i18n.locale + row.url_link"
                        :target="row.linkTarget"
                        class="button button-border
                               hidden-sm hidden-xs button-rounded
                               button-large noleftmargin topmargin-sm">
                       {{ row.link_text }}
                     </router-link>
                   </div>
                 </div>
               </div>
               
               <div v-if="row.medie_position === 'none'"
                    class="topmargin-sm bottommargin-sm">
                 <div class="heading-block">
                   <h2 v-if="row.headline">{{ row.headline }}</h2>
                   <span v-html="row.text"></span>
                   <router-link v-if="row.url_link" :to="'/' + $i18n.locale + row.url_link"
                                          :target="row.linkTarget"

                      lass="button button-border
                            button-rounded button-large 
                            noleftmargin topmargin-sm">{{ row.link_text }}</router-link>
                 </div>
               </div>
             </div>
         </div>
         <div class="container clearfix">
           <div class="col-full" id="gallery" v-if="carousel">
             <carousel :perPageCustom="[[0, 2], [600,4], [1000, 5], [1200, 5], [1400, 5]]" :navigationEnabled="false" :autoplay="true" :autoplayHoverPause="true" :paginationEnabled="true" :autoplayTimeout=5000 :loop="true">
               <slide v-for="row in carousel" :key="row">
                 <img v-lazy="row.url">
               </slide>
             </carousel>
           </div>
         </div>
       </div>
       <div v-if="gmapCenter.lat && gmapCenter.lng">
         <GmapMap :center="gmapCenter"
                  :zoom="14"
                  map-type-id="roadmap"
                  :options="gmapOptions"   
                  :style="'width: 100%; height:' + gmapHeight ">
           <GmapMarker
             :key="index"
             v-for="(m, index) in gmapMarkers"
             :position="m.position"
             infoText="test"
             :icon="m.icon"
             :clickable="true"
             :draggable="false"
             @click="toggleInfoWindow(m,index)">
           </GmapMarker>
           <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
           </GmapInfoWindow>
         </GmapMap>
       </div>
       </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import Contactform from './Contactform'

export default {
   name: 'Contentrows',
   props: ['contentrows', 'gmap', 'carousel', 'globalSet'],
   components: {
      Carousel,
      Slide,
      Contactform
   },
   data() {
       return {
           infoWindowPos: null,
           infoWinOpen: false,
           currentMidx: null,
           setLastColumnBreak: false,
           infoOptions: {
               content: '',
               pixelOffset: {
                   width: 0,
                   height: -35
               }
           },
           gmapCenter: {},
           gmapMarkers: [],
           gmapHeight: 0,
           gmapOptions: {
               styles: [
                   {
                       "featureType": "water",
                       "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#d3d3d3"
                            }
                        ]
                   },
             {
                 "featureType": "water",
                 "elementType": "labels.text.fill",
                 "stylers": [
                     {
                         "color": "#e66d1c"
                     }
                 ]
             },
             {
                 "featureType": "transit",
                 "stylers": [
                     {
                         "color": "#808080"
                     },
                     {
                         "visibility": "off"
                     }
                 ]
             },
             {
                 "featureType": "road.highway",
                 "elementType": "geometry.stroke",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#b3b3b3"
                     }
                 ]
             },
             {
                 "featureType": "road.highway",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "color": "#ffffff"
                     }
                 ]
             },
             {
                 "featureType": "road.local",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#ffffff"
                     },
                     {
                         "weight": 1.8
                     }
                 ]
             },
             {
                 "featureType": "road.local",
                 "elementType": "geometry.stroke",
                 "stylers": [
                     {
                         "color": "#d7d7d7"
                     }
                 ]
             },
             {
                 "featureType": "poi",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#ebebeb"
                     }
                 ]
             },
             {
                 "featureType": "administrative",
                 "elementType": "geometry",
                 "stylers": [
                     {
                         "color": "#a7a7a7"
                     }
                 ]
             },
             {
                 "featureType": "road.arterial",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "color": "#ffffff"
                     }
                 ]
             },
             {
                 "featureType": "road.arterial",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "color": "#ffffff"
                     }
                 ]
             },
             {
                 "featureType": "landscape",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#efefef"
                     }
                 ]
             },
             {
                 "featureType": "road",
                 "elementType": "labels.text.fill",
                 "stylers": [
                     {
                         "color": "#696969"
                     }
                 ]
             },
             {
                 "featureType": "administrative",
                 "elementType": "labels.text.fill",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#737373"
                     }
                 ]
             },
             {
                 "featureType": "poi",
                 "elementType": "labels.icon",
                 "stylers": [
                     {
                         "visibility": "off"
                     }
                 ]
             },
             {
                 "featureType": "poi",
                 "elementType": "labels",
                 "stylers": [
                     {
                         "visibility": "off"
                     }
                 ]
             },
             {
                 "featureType": "road.arterial",
                 "elementType": "geometry.stroke",
                 "stylers": [
                     {
                         "color": "#d6d6d6"
                     }
                 ]
             },
             {
                 "featureType": "road",
                 "elementType": "labels.icon",
                 "stylers": [
                     {
                         "visibility": "off"
                     }
                 ]
             },
             {},
             {
                 "featureType": "poi",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "color": "#dadada"
                     }
                 ]
             }
              ]
           }
       }
   },
   watch: {
       gmap: function() {
           this.gmapCenter = {}
           this.gmapMarkers = []
           if(this.gmap.content_content_grid.length > 0) {
               this.feedGmap()
           }
      }
   },

   methods: {
       toggleInfoWindow: function(marker, idx) {
           this.infoWindowPos = marker.position;
           this.infoOptions.content = marker.infoText;

           //check if its the same marker that was selected if yes toggle
           if (this.currentMidx == idx) {
             this.infoWinOpen = !this.infoWinOpen;
           }
           //if different marker set infowindow to open and reset current marker index
           else {
             this.infoWinOpen = true;
             this.currentMidx = idx;
           }
      },
      lastcolumnbreak(value) {
          return value;
      },
      feedGmap() {
          this.gmapCenter = {
              lat: parseFloat(this.globalSet[0].defaultGmapLat),
              lng: parseFloat(this.globalSet[0].defaultGmapLng)
          }
          this.gmapHeight = (this.globalSet[0].gmapHeight) + 'px'
          var gmapMarkers = this.gmap.content_content_grid
          for(let i = 0; i < gmapMarkers.length; i++) {

               var infoText = '<h4 class="nomargin">'
                   + gmapMarkers[i].markerHeadline + '</h4><div>'
                   + gmapMarkers[i].markerText + '</div>'
                   if(gmapMarkers[i].markerLink) {
                       infoText = infoText + '<div><a target="_blank" href="'
                                + gmapMarkers[i].markerLink + '" rel="nofollow">'
                                + gmapMarkers[i].markerLinkText + '</a></div><div>'
                   }
                   if(gmapMarkers[i].markerImage.length > 0) {
                       infoText = infoText + '<div><img v-lazy="'
                                + gmapMarkers[i].markerImage[0].url.replace('default/uploads/','default/uploads/_googleMapImage/') +'"></div>'
                   }                    
                             
               var marker = {
                   position: {
                       lat: parseFloat(gmapMarkers[i].latitude),
                       lng: parseFloat(gmapMarkers[i].longtitude)
                   },
                   icon: {
                       url: gmapMarkers[i].markerIcon[0].url
                   },
                   infoText: infoText
               }                     
               this.$set(this.gmapMarkers, i, marker)
         }
      },
      mediapos (text,width,position) {
         var output1 = ''
         var output2 = ''
         if(text == '') {
            output1 = 'col_' + width + ' '
         } else {
            output1 = 'col_' + width + ' '
         }
         if(position == 'right') {
            output2 = 'col_last '
         }
         return output1 + output2
      },
      getopsitewith (width) {
         switch(width) {
            case 'half':
               return 'col_half'
            case 'one_third':
               return 'col_two_third'
            case 'two_third':
               return 'col_one_third'
            case 'one_forth':
               return 'col_three_forth'
            case 'three_forth':
               return 'col_one_forth'
            case 'one_fifth':
               return 'col_four_fifth'
            case 'two_fifth':
               return 'col_three_fifth'
            case 'three_fifth':
               return 'col_two_fifth'
            case 'four_fifth':
               return 'col_one_fifth'
            case 'one_sixth':
               return 'col_five_sixth'
            case 'five_sixth':
               return 'col_one_sixth'
            default:
               return 'col_full'
         }
      }
   }
}
</script>

<style>
</style>
