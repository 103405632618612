<template>
  <div>    
    <section id="content">
      <div class="content-wrap">
       <div class="gmapborderbottom" v-if="showMap">
         <GmapMap :center="gmapCenter"
                  :zoom="7"
                  map-type-id="roadmap"
                  :options="gmapOptions"   
                  :style="'width: 100%; height:' + gmapHeight ">
           <GmapCluster imagePath="/assets/images/gmap/">
           <GmapMarker
             :key="index"
             v-for="(m, index) in gmapMarkers"
             :position="m.position"
             infoText="test"
             :icon="m.icon"
             :clickable="true"
             :draggable="false"
             @click="toggleInfoWindow(m,index)">
           </GmapMarker>
           </GmapCluster>
           <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
           </GmapInfoWindow>
         </GmapMap>
       </div>
       
       <Searchfilter v-bind:sortFilters="sortFilters" v-bind:rentType="rentType" v-bind:rentCity="rentCity" v-bind:selectedCity="selectedCity" v-bind:selectedRenttype="selectedRenttype" v-if="sortFilters.renttypes" ref="SearchFilter"/>

       <div class="container rentalsoverview">
         <div class="row">
           <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 bottommargin">
             <h2>{{ $t('Available rentals at industribo') }}</h2>
             <div class="stroke"></div>
           </div>
         </div>
         <div class="row">
           <div v-if="this.viewType == 'rentals'" style="position:relative;">
             <div
                  :data-m2="rental.strelse"
                  :data-city="rental.boligblok_city"
                  :data-renttype="rental.boligtype_title"
                  :class="defaultListClass"
                  v-for="rental in rentals"
                  :key="rental">
               <router-link :to="'/' + $i18n.locale + $t('tenacypath') + '/' + rental.slug">
                 <div class="rental">
                   <div v-if="listLayout == 'grid'" class="grid">
                     <div v-if="rental.lejlighed_status == 'available'" class="tag for-rent">{{ $t('Available') }}</div>
                     <div v-if="rental.lejlighed_status == 'onhold'" class="tag reserved">{{ $t('Reserved') }}</div>
                     <div v-if="rental.lejlighed_status == 'soled'" class="tag not-for-rent">{{ $t('Occupied') }}</div>
                     <img v-if="rental.bolig_blok[0].facade[0]"
                          v-lazy="rental.bolig_blok[0].facade[0].urlLarge"
                          :alt="rental.bolig_blok[0].facade[0].title">
                     <img v-else
                          :src="noimagewide"
                          alt="Ingen billede af bygning">                     
                     <div class="label">
                       <ul>
                         <li><h3>{{ rental.bolig_blok[0].title }}</h3></li>
                         <li class="heighlight">{{ rental.title }}</li>
                         <li class="heighlight">{{ rental.rentalHeadline.substring(0,40)+".." }} </li>
                         <li class="heighlight">{{ rental.bolig_blok[0].streetandnum }}, {{ rental.bolig_blok[0].buildingZipcode, }} {{ rental.bolig_blok[0].city }}</li>
                         <li class="heighlight" v-if="rental.lejlighed_status == 'available'"><B>{{ rental.strelse.toLocaleString('da-DK') }} m², {{rental.rent.toLocaleString('da-DK') }} {{ $t('Currency a month') }}</B></li>

                       </ul>
                     </div>
                   </div>
                   <div v-if="listLayout == 'list'" class="list">
                     <div v-if="rental.lejlighed_status == 'soled'" class="occupied rentallabel">
                       <div class="text">{{ $t('Occupied') }}</div>
                     </div>
                     <div v-if="rental.lejlighed_status == 'available'" class="free rentallabel">
                       <div class="text">{{ $t('Available') }}</div>
                     </div>
                     <div v-if="rental.lejlighed_status == 'onhold'" class="reserved rentallabel">
                       <div class="text">{{ $t('Reserved') }}</div>
                     </div>

                     <div class="imagecontainer">
                       <img v-if="rental.bolig_blok[0].facade[0]" style="vertical-align:top;"
                            v-lazy="rental.bolig_blok[0].facade[0].urlSmall"
                            :alt="rental.bolig_blok[0].facade[0].title">
                       <img v-else
                          :src="noimagesmall133"
                          :alt="$t('No picture available')">                     
                     </div>

                     <span style="margin-left:15px; display: inline-block;">
                       <h3>{{ rental.rentalHeadline }}</h3>
                         <ul style="list-style:none;">
                           <li><b>{{ rental.title }}</b></li>
                           <li>{{ $t('Address:') }} {{ rental.bolig_blok[0].streetandnum }}, {{ rental.bolig_blok[0].buildingZipcode }} {{ rental.bolig_blok[0].city }}</li>
                           <li v-if="rental.lejlighed_status == 'available'">{{ $t('Rental size') }} {{ rental.strelse.toLocaleString('da-DK') }} m²</li>
                           <li v-if="rental.lejlighed_status == 'available'">{{ $t('Monthly rent') }} {{ rental.rent.toLocaleString('da-DK') }} kr.</li>
                           <li>{{ $t('Category') }} {{ rental.boligtyper[0].title }}</li>
                         </ul>
                     </span>
                   </div>
                 </div>
               </router-link>
             </div>
           </div>
           <div v-if="this.viewType == 'buildings'">
             <div :data-m2="building.buildingSize"
                  :data-city="building.city"
                  :data-renttype="building.buildingtype_title"
                  :class="defaultListClass"
                  v-for="building in buildings"
                  :key="building">
               <router-link :to="'/' + $i18n.locale + '/ejendom/' + building.slug">
                 <div class="rental">
                   <div v-if="listLayout == 'grid'" class="grid">
                     <div v-if="building.rentalsDetails.rentalStatus == 'available'" class="tag for-rent">{{ $t('Availalble rentals') }}</div>
                     <div v-if="building.rentalsDetails.rentalStatus == 'soled'" class="tag not-for-rent">{{ $t('All occupied') }}</div>

                     <img v-if="building.facade[0]"
                          v-lazy="building.facade[0].urlLarge"
                          :alt="building.facade[0].title">
                     <img v-else
                          :src="noimagewide"
                          alt="Ingen billede af bygning">
                     <div class="label">
                       <ul style="list-style:none;">
                         <li><h3>{{ building.title }}</h3>
                         <li class="heighlight">{{ building.streetandnum }}, {{ building.buildingZipcode }} {{ building.city }}</li>
                         <li class="heighlight" v-if="building.rentalsDetails.rentalStatus == 'available'">{{ $t('Available rentals count') }} {{ building.rentalsDetails.counter }}</li>
                         <li class="heighlight" v-if="building.rentalsDetails.rentalStatus == 'available'">{{ $t('Area availalble for rent') }} {{ building.rentalsDetails.smallestSize.toLocaleString('da-DK') }} - {{ building.rentalsDetails.largestSize.toLocaleString('da-DK') }} m²</li>
                         <li class="heighlight" v-if="building.rentalsDetails.rentalStatus == 'available'">{{ $t('Yearly rent pr. m² starting from') }} {{ building.rentalsDetails.yearlyRentPrSqureMeter.toLocaleString('da-DK') }}  </li>
                       </ul>
                     </div>
                   </div>
                   <div v-if="listLayout == 'list'" class="list">
                     <div v-if="building.rentalsDetails.rentalStatus == 'soled'" class="occupied rentallabel">
                       <div class="text">{{ $t('Occupied label') }}</div>
                     </div>
                     <div v-if="building.rentalsDetails.rentalStatus == 'available'" class="free rentallabel">
                       <div class="text">{{ $t('Availalble label') }}</div>
                     </div>
                     
                     <div class="imagecontainer">
                       <img style="vertical-align:top;" v-if="building.facade[0]"
                            v-lazy="building.facade[0].urlSmall"
                            :alt="building.facade[0].title">
                       <img v-else
                            :src="noimage"
                            alt="Ingen billede af bygning">
                     </div>
                     <span style="margin-left:15px; display: inline-block;">
                       <h3>{{ building.title }}</h3>
                       <ul style="list-style:none;">
                         <li >{{ $t('Address') }} {{ building.streetandnum }}, {{ building.buildingZipcode }} {{ building.city }}</li>
                         <li v-if="building.rentalsDetails.rentalStatus == 'available'">Ledige lejemål: {{ building.rentalsDetails.counter }}</li>
                         <li v-if="building.rentalsDetails.rentalStatus == 'available'">{{ $t('Area availalble for rent') }} {{ building.rentalsDetails.smallestSize.toLocaleString('da-DK') }} - {{ building.rentalsDetails.largestSize.toLocaleString('da-DK') }} m²</li>
                         <li v-if="building.rentalsDetails.rentalStatus == 'available'">{{ $t('Yearly rent pr. m² starting from') }} {{ building.rentalsDetails.yearlyRentPrSqureMeter.toLocaleString('da-DK') }}  kr.</li>
                       </ul>
                     </span>
                   </div>                   
                 </div>
               </router-link>
             </div>
           </div>
           <div v-if="emptyresult" class="col-xs-12-col-sm-12 col-md-12 col-lg-12 col-xl-12">
             <h3>{{ $t('No available rentals found based on your criteria - try again') }}</h3>
           </div>
         </div>
       </div>
      </div>
    </section>
    
    <Calltoactionbar v-bind:globalSet="globalSet"/>
  </div>
</template>

<script>
    import { GET_PROPERTY_QUERY } from '../graphql/buildingQueires'
    import { GET_BUILDINGS_QUERY } from '../graphql/buildingQueires.js'
    import { siteId } from '../generalSettings.js'
    import Searchfilter from './Searchfilter'
    import Calltoactionbar from './Calltoactionbar'
        
    export default {
    name: 'Buildingoverview',
    props: ['globalSet', 'selectedCity','selectedRenttype'],

    components: {
        'Searchfilter': Searchfilter,
        'Calltoactionbar': Calltoactionbar
    },
    data() {
return {
            noimagebig: require('../../public/assets/images/noimage.jpg'),
            noimage: require('../../public/assets/images/noimage-small.jpg'),
            noimagewide: require('../../public/assets/images/noimage-small-wide.jpg'),
            noimagesmall133: require('../../public/assets/images/noimage-small-133.jpg'),
            index: null,
            showMap:false,
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            rentals: [],
            buildings: [],
            viewType: 'rentals',
            sortFilters: [],
            emptyresult: false,
            rentType: 0,
            rentCity: 0,
            listLayout: 'list',
            defaultListClass: 'col-xs-12-col-sm-12 col-md-12 col-lg-12 col-xl-12 rental-item',
                infoOptions: {
                    content: '',
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                gmapCenter: {
                    lat: 55.9396761,
                    lng: 9.5155848
                },
                gmapMarkers: [],
                gmapHeight: 0,
                gmapOptions: {
                styles: [
                   {
                       "featureType": "water",
                       "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#d3d3d3"
                            }
                        ]
                   },
             {
                 "featureType": "water",
                 "elementType": "labels.text.fill",
                 "stylers": [
                     {
                         "color": "#e66d1c"
                     }
                 ]
             },
             {
                 "featureType": "transit",
                 "stylers": [
                     {
                         "color": "#808080"
                     },
                     {
                         "visibility": "off"
                     }
                 ]
             },
             {
                 "featureType": "road.highway",
                 "elementType": "geometry.stroke",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#b3b3b3"
                     }
                 ]
             },
             {
                 "featureType": "road.highway",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "color": "#ffffff"
                     }
                 ]
             },
             {
                 "featureType": "road.local",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#ffffff"
                     },
                     {
                         "weight": 1.8
                     }
                 ]
             },
             {
                 "featureType": "road.local",
                 "elementType": "geometry.stroke",
                 "stylers": [
                     {
                         "color": "#d7d7d7"
                     }
                 ]
             },
             {
                 "featureType": "poi",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#ebebeb"
                     }
                 ]
             },
             {
                 "featureType": "administrative",
                 "elementType": "geometry",
                 "stylers": [
                     {
                         "color": "#a7a7a7"
                     }
                 ]
             },
             {
                 "featureType": "road.arterial",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "color": "#ffffff"
                     }
                 ]
             },
             {
                 "featureType": "road.arterial",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "color": "#ffffff"
                     }
                 ]
             },
             {
                 "featureType": "landscape",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#efefef"
                     }
                 ]
             },
             {
                 "featureType": "road",
                 "elementType": "labels.text.fill",
                 "stylers": [
                     {
                         "color": "#696969"
                     }
                 ]
             },
             {
                 "featureType": "administrative",
                 "elementType": "labels.text.fill",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#737373"
                     }
                 ]
             },
             {
                 "featureType": "poi",
                 "elementType": "labels.icon",
                 "stylers": [
                     {
                         "visibility": "off"
                     }
                 ]
             },
             {
                 "featureType": "poi",
                 "elementType": "labels",
                 "stylers": [
                     {
                         "visibility": "off"
                     }
                 ]
             },
             {
                 "featureType": "road.arterial",
                 "elementType": "geometry.stroke",
                 "stylers": [
                     {
                         "color": "#d6d6d6"
                     }
                 ]
             },
             {
                 "featureType": "road",
                 "elementType": "labels.icon",
                 "stylers": [
                     {
                         "visibility": "off"
                     }
                 ]
             },
             {},
             {
                 "featureType": "poi",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "color": "#dadada"
                     }
                 ]
             }
              ]
           }
           }
        },
        created() {
             this.fetchRentalsData()
             this.fetchBuildingData()
             
             this.responsiveHandler()
             window.addEventListener('resize', this.responsiveHandler)
        },
    methods: {
            getGoogleClusterInlineSvg(color) {
                var encoded = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' + color + '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>');

                return ('data:image/svg+xml;base64,' + encoded);
            },
            responsiveHandler () {
                if(window.innerWidth < 991) {
                    this.defaultListClass = 'col-xs-12 col-sm-6 col-md-5 col-lg-5 col-xl-5 rental-item'
                    this.listLayout = 'grid'
                }
            },
            goto(url) {
                this.$router.push(url)
            },
            showModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
            forceRerender() {
                if(this.rentType == 0) {
                   this.rentType = 1
                   this.rentCity = 1
                } else if(this.rentType == 1) {
                   this.rentType = 0
                   this.rentCity = 0
                }
            },
            toggleInfoWindow: function(marker, idx) {
                this.infoWindowPos = marker.position;
                this.infoOptions.content = marker.infoText;

                //check if its the same marker that was selected if yes toggle
                if (this.currentMidx == idx) {
                    this.infoWinOpen = !this.infoWinOpen;
                } else {
                    this.infoWinOpen = true;
                    this.currentMidx = idx;
                }
            },
            feedGmap: function() {
                this.gmapHeight = '550px'
                var gmapMarkers
                var latitude
                var longtitude
                var size
                var infoText
                if(this.viewType == 'rentals') {
                    gmapMarkers = this.rentals
                } else if(this.viewType == 'buildings') {
                    gmapMarkers = this.buildings
                }
                for(let i = 0; i < gmapMarkers.length; i++) {

                   /*
                    * mapping the data depending what viewType is set to
                    */
                   if(this.viewType == 'rentals') {
                       latitude = gmapMarkers[i].latitude
                       longtitude = gmapMarkers[i].longtitude
                       size = gmapMarkers[i].strelse
                   } else if(this.viewType == 'buildings') {
                       latitude = gmapMarkers[i].building_latitude
                       longtitude = gmapMarkers[i].building_longtitude
                       size = gmapMarkers[i].buildingSize            
                   }
                   if(this.viewType == 'rentals') {                
                       infoText = '<h3 class="nomargin mapheadline">'
                       + gmapMarkers[i].title + '</h4><p>' + gmapMarkers[i].rentalHeadline  + '</p><img src="' + gmapMarkers[i].bolig_blok[0].facade[0].urlSmall +'"><p><a href="https://maps.google.com/?q='+ gmapMarkers[i].latitude +','+ gmapMarkers[i].longtitude +'" target="_NEW">Find vej</p>'
                   }
                   if(this.viewType == 'buildings') {
                       infoText = '<h3 class="nomargin mapheadline">'
                       + gmapMarkers[i].title + '</h4><p>' + gmapMarkers[i].facade_title  + '</p><img src="' + gmapMarkers[i].facade[0].urlSmall +'"><p><a href="https://maps.google.com/?q='+ gmapMarkers[i].building_latitude +','+ gmapMarkers[i].building_longtitude +'" target="_NEW">Find vej</p>'
                   }
                   var marker = {
                       position: {
                           lat: parseFloat(latitude),
                           lng: parseFloat(longtitude)
                       },
                       icon: {
                           url: '/assets/images/map-marker.png'
                       },
                       m2: size,
                       infoText: infoText
                   }
                   this.$set(this.gmapMarkers, i, marker)
                }
            },
            changeView: function() {
                this.viewType = this.$refs['SearchFilter'].$refs['viewType'].value
                this.feedGmap()
                this.buildSortData()
                this.forceRerender()
                this.fetchBuildingData()
            },
            fetchRentalsData: function() {
                this.$apollo.query({
                    query: GET_PROPERTY_QUERY,
                    variables: {
                        siteId: this.$i18n.siteId
                    }
                }).then(function (response) {
                    if(response.data.property.length > 0) {
                        for(let i=0; i < response.data.property.length; i++) {
                            if(response.data.property[i].bolig_blok.length > 0) {

                            if(response.data.property[i].boligtyper.length == 0) {
                                response.data.property[i]['boligtype_title'] = 'undefined';
                            } else {
                                response.data.property[i]['boligtype_title'] = response.data.property[i].boligtyper[0].title;
                            }
                            if(response.data.property[i].bolig_blok.length == 0) {
                                response.data.property[i]['boligblok_city'] = 'undefined';
                            } else {
                                response.data.property[i]['boligblok_city'] = response.data.property[i].bolig_blok[0].city;
                            }
                                       this.rentals.push(response.data.property[i]);
                            }
                        } 
                                                                     console.log(this.rentals)
                     } else {
                         this.rentals = {};
                     }                                         
                     this.feedGmap()
                     this.buildSortData()
                }.bind(this))
                return true;                         
           },
           buildSortData () {
               this.sortFilters.renttypes = []
               this.sortFilters.m2 = []
               this.sortFilters.cities = []
               this.sortFilters.slideRangeValue = []
               this.sortFilters.maxSquareMeters = 0

               if(this.viewType == 'rentals') {
                    for(let i=0; i < this.rentals.length; i++) {
                       if(this.rentals[i].boligtyper[0].title.length &&
                           this.sortFilters.renttypes.indexOf(this.rentals[i].boligtyper[0].title) == -1) {
                           this.sortFilters.renttypes.push(this.rentals[i].boligtyper[0].title)
                       }
                       if(this.rentals[i].bolig_blok[0].city.length &&
                           this.sortFilters.cities.indexOf(this.rentals[i].bolig_blok[0].city) == -1) {
                           this.sortFilters.cities.push(this.rentals[i].bolig_blok[0].city)
                       }
                       if(this.rentals[i].strelse > 0 &&
                           this.sortFilters.m2.indexOf(this.rentals[i].strelse) == -1) {
                           this.sortFilters.m2.push(this.rentals[i].strelse)
                       }
                   }               
                } else if(this.viewType == 'buildings') {
                   for(let i=0; i < this.buildings.length; i++) {
                       if(this.buildings[i].buildingType[0].title.length &&
                           this.sortFilters.renttypes.indexOf(this.buildings[i].buildingType[0].title) == -1) {
                           this.sortFilters.renttypes.push(this.buildings[i].buildingType[0].title)
                       }
                       if(this.buildings[i].city.length &&
                           this.sortFilters.cities.indexOf(this.buildings[i].city) == -1) {
                           this.sortFilters.cities.push(this.buildings[i].city)
                       }
                       if(this.buildings[i].buildingSize > 0 &&
                           this.sortFilters.m2.indexOf(this.buildings[i].buildingSize) == -1) {
                           this.sortFilters.m2.push(this.buildings[i].buildingSize)
                       }
                   }
               }
               this.sortFilters.maxSquareMeters = Math.max.apply(null,this.sortFilters.m2);
               this.sortFilters.slideRangeValue = [0, this.sortFilters.maxSquareMeters]
          },
          calcDetailsAboutAvailableRentals: function(slug) {
               var counter=0;
               var areaCounter=0;
               var yearlyRentPrSqureMeter=0;
               const monthlyRentPrSquareMeter = [];
               const rentalSizes = [];                          
               for(let i=0; i < this.rentals.length; i++) {
                   if(this.rentals[i].bolig_blok[0].slug == slug && this.rentals[i].lejlighed_status == 'available') {
                       counter++;
                       monthlyRentPrSquareMeter.push(Math.round(this.rentals[i].rent/this.rentals[i].strelse))
                       areaCounter = areaCounter + this.rentals[i].strelse
                       rentalSizes.push(this.rentals[i].strelse)
                   }
               }

               var smallestMonthlyRent = Math.min(...monthlyRentPrSquareMeter);
               if(smallestMonthlyRent === Infinity) {
                   smallestMonthlyRent = 0;
               }
               var returnArray = [];
               returnArray['counter'] = counter;
               returnArray['areaCounter'] = areaCounter;
               returnArray['yearlyRentPrSqureMeter'] = (smallestMonthlyRent*12);
               returnArray['smallestSize'] = Math.min(...rentalSizes)
               returnArray['largestSize'] = Math.max(...rentalSizes)
               if(counter > 0) {                 
                   returnArray['rentalStatus'] = 'available'
               } else {
                   returnArray['rentalStatus'] = 'soled'
               }                          
               return returnArray;                 
          },
          fetchBuildingData: function() {
                this.$apollo.query({
                    query: GET_BUILDINGS_QUERY,
                    variables: {
                        siteId: this.$i18n.siteId
                    }
                }).then(function (response) {
                    if(response.data.building.length > 0) {
                         for(let i=0; i < response.data.building.length; i++) {
                            if(response.data.building[i].buildingType[0].length == 0) {
                                response.data.building[i]['buildingtype_title'] = 'undefined';
                            } else {
                                response.data.building[i]['buildingtype_title'] = response.data.building[i].buildingType[0].title;
                            }
                            response.data.building[i]['rentalsDetails'] = this.calcDetailsAboutAvailableRentals(response.data.building[i].slug)
                         }
                                          
                         this.buildings = response.data.building;
                    } else {
                         this.buildings = {};
                    }
                }.bind(this))
           },
       }
     }
</script>

<style>
</style>
