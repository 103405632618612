<template>
  <div>
  <Slider/>
  <Contentrows v-bind:contentrows="entries" v-bind:globalSet="globalSet"/>
  <Searchproperty v-bind:globalSet="globalSet"/>
  </div>
</template>

<script>
  import Slider from './Slider'
  import Contentrows from './Contentrows'
  import Searchproperty from './Searchproperty'
  import { GET_FRONTPAGE_CONTENTROWS_QUERY } from '../graphql/contentrowsQueries';

  export default {
     name: 'Frontpage',
     props: ['globalSet'],
     components: {
        'Slider': Slider,
        'Contentrows': Contentrows,
        'Searchproperty': Searchproperty
     },
     data() {
       return {
          entries: []
       }
     },
     created() {
         this.fetchData()
     },
     methods: {
         fetchData () {
             this.$apollo.query({
                 query: GET_FRONTPAGE_CONTENTROWS_QUERY,
                 variables: {
                     siteId: this.$i18n.siteId
                 }
              }).then(function (response) {
                 this.entries = response.data.entries;
              }.bind(this))
      }
     }
  }
</script>

<style>
</style>
