<template>
<div class="row nomargin">
  <div class="nopadding col-xs-12 col-sm-12 col-md-12 col-lg-12 col-lg-12" v-for="global in globalSet" :key="global">
    <a :href="'/' + $i18n.locale + global.callToActionLinkOnFrontpage" class="button button-full center tright header-stick">
      <div class="container clearfix">
        {{ global.callToActionTextFrontpage }}
      </div>
    </a>
  </div>
</div>
</template>

<script>
  export default {
      name: 'Calltoactionbar',
      props: ['globalSet']
  }
</script>

<style>

</style>
