<template>
<div>
  <div class="searchinput">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
          <label>{{ $t('Type of rental') }}</label>
          <div class="select">
          <select name="viewType" ref="viewType" @change="$parent.changeView()">
            <option value="0">{{ $t('Choose') }}</option>
            <option value="buildings">{{ $t('Property') }}</option>
            <option value="rentals" selected>{{ $t('Rentals') }}</option>
          </select>
          <span class="focus"></span>
          </div>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
          <label>{{ $t('Choose city') }}</label>
          <div class="select">
          <select name="city" ref="selectCity" @change="runFilter()" :key="rentCity">
            <option value="0">{{ $t('Choose') }}</option>
            <option v-for="city in sortFilters.cities"
                    :key="city"
                    :value="city"
                    @onchange="runFilter()">
              {{ city }}
            </option>
          </select>
          <span class="focus"></span>
          </div>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
          <label for="renttype">{{ $t('Select type') }}</label>
          <div class="select">
          <select id="renttype" name="renttype" ref="selectRenttype" @change="runFilter()" :key="rentType">
            <option value="0">{{ $t('Choose') }}</option>
            <option v-for="renttype in sortFilters.renttypes"
                    :key="renttype"
                    :value="renttype">
              {{ renttype }}
            </option>
          </select>
          <span class="focus"></span>
          </div>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 slidesearch">
          
          <vue-range-slider
            class="sliderrange"
            v-if="this.sortFilters"
            :min="parseInt(0)"
            :max="this.sortFilters.maxSquareMeters"
            :formatter="formatter"
            v-model="slideRangeValue">
          </vue-range-slider>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px 0px 30px 0px;">
        <span style="padding-right: 10px;">
          <button v-if="!showMap" @click="enableMap()" class="btn btn-default"><i class="far fa-map"></i> {{ $t('Show map') }}</button>
          <button v-if="showMap" @click="hideMap()" class="btn btn-default"><i class="far fa-map"></i> {{ $t('Hide map') }}</button>                                                                                                                     </span>
        <span><button @click="switchLayout('grid')" class="btn btn-default hidden-xs"><i class="fas fa-th"></i> {{ $t('Grid mode') }}</button></span>
        <span>&nbsp;<button @click="switchLayout('list')" class="btn btn-default hidden-xs"><i class="fas fa-bars"></i> {{ $t('Listing mode') }}</button></span>       
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import { siteId } from '../generalSettings.js'

  import 'vue-range-component/dist/vue-range-slider.css'
  import VueRangeSlider from 'vue-range-component'

  export default {
    name: 'Searchfilter',
    props: ['sortFilters', 'rentType','rentCity', 'selectedCity', 'selectedRenttype'],
    components: {
        VueRangeSlider
    },
    watch: {
        slideRangeValue: function() {
            this.runFilter(null,);
        },
        rentCity: function() {
            this.slideRangeValue = this.sortFilters.slideRangeValue
        }
    },
    data() {
        return {
            preventScroll:false,
            firstLoad: true,
            siteId: siteId,
            showMap: this.$parent.showMap,
            slideMaxValue: this.sortFilters.maxSquareMeters,
            slideRangeValue: this.sortFilters.slideRangeValue
        }
    },
    mounted() {
        this.clearFilter()
        this.loadProps()
    },
methods: {
        hideMap() {
            this.$parent.showMap = false;
            this.showMap = false;
        },
        enableMap() {
           this.$parent.showMap = true;
           this.showMap = true;
        },
        switchLayout(type) {
            if(type == 'grid') {
                 this.$parent.defaultListClass = 'col-xs-12-col-sm-6 col-md-4 col-lg-4 col-xl-4 rental-item'
                 this.$parent.listLayout = 'grid'
            }
            if(type == 'list') {
                 this.$parent.defaultListClass = 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 rental-item'
                 this.$parent.listLayout = 'list'
            }
        },
        formatter: function(value){
            if(value == null) {
                return 0 + ' m²'
            } else {
                return value.toLocaleString('da-DK') + ' m²'
            }
         },
         loadProps: function () {
             var applyFilter = 0;
             if(this.selectedCity) {
                this.$refs['selectCity'].value = this.selectedCity;
                applyFilter = 1
             }
             if(this.selectedRenttype) {
                this.$refs['selectRenttype'].value = this.selectedRenttype;
                applyFilter = 1
             }
             if(applyFilter == 1) {
                this.runFilter()
             }
         },
         runFilter: function () {
               if(this.firstLoad) {
                   this.firstLoad = false
               } else {
               var allBuildings = document.getElementsByClassName('rental-item')

               var rentType = this.$refs['selectRenttype'].value
               var city = this.$refs['selectCity'].value
               var minM2 = this.slideRangeValue[0]
               var maxM2 = this.slideRangeValue[1]

               for(let i=0; i < allBuildings.length; i++) {

                   if(city != 0 && rentType != 0) {
                   if(city == allBuildings[i].dataset.city
                          && rentType == allBuildings[i].dataset.renttype
                          && allBuildings[i].dataset.m2 >= minM2
                          && allBuildings[i].dataset.m2 <= maxM2) {
                       allBuildings[i].hidden = false
                   } else {
                       allBuildings[i].hidden = true
                   }
                   }
                   if(city == 0 && rentType != 0) {
                   if(rentType == allBuildings[i].dataset.renttype
                          && allBuildings[i].dataset.m2 >= minM2
                          && allBuildings[i].dataset.m2 <= maxM2) {
                       allBuildings[i].hidden = false
                   } else {
                       allBuildings[i].hidden = true
                   }
                   }
                   if(city != 0 && rentType == 0) {
                   if(city == allBuildings[i].dataset.city
                          && allBuildings[i].dataset.m2 >= minM2
                          && allBuildings[i].dataset.m2 <= maxM2) {
                       allBuildings[i].hidden = false
                   } else {
                       allBuildings[i].hidden = true
                   }
                   }
                   if(city == 0 && rentType == 0) {
                   if(allBuildings[i].dataset.m2 >= minM2
                          && allBuildings[i].dataset.m2 <= maxM2) {
                       allBuildings[i].hidden = false
                   } else {
                       allBuildings[i].hidden = true
                   }
                   }

               }
               if(rentType == 0
                   && city == 0
                   && minM2 == 0
                   && maxM2 == this.sortFilters.maxSquareMeters) {
                   this.clearFilter()
               }
               var hidden = 0; 
               for(let i=0; i < allBuildings.length; i++) {
                   if(allBuildings[i].hidden == true) {
                       hidden++
                   }
               }
               this.$parent.emptyresult = false
               if(hidden == allBuildings.length) {
                   this.$parent.emptyresult = true
               } else {
                   this.$parent.emptyresult = false
               }

               if(!this.preventScroll) {
                   this.$scrollTo('.searchinput')
                   this.preventScroll = true;
               }
         }
         },
         clearFilter: function() {
             var allBuildings = document.getElementsByClassName('rental-item')

             for(let i=0; i < allBuildings.length; i++) {
                 allBuildings[i].hidden = false;
             }
         }
    }
  }
</script>
