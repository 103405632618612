<template>
  <div>
    <section id="page-title">
      <div class="container clearfix">
        <h1 class="building-info" style="float:left;">{{ building.title }}</h1>
      </div>
    </section>
    
    <section id="content">
      <div class="content-wrap">
        <div class="container clearfix buildingheadline">
          <div class="col_full nomargin">
            <div class="col_full nomargin ">
              <h2>{{ building.facade_title }}</h2>
              <div class="stroke"></div>
            </div>
          </div>
          <div class="col_full mb-30">
            <div class="col_full nomargin">
              <h3>{{ building.extra_information }}</h3>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row padding-bottom-25 hidden-lg hidden-md">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12breadcrumbs">
              <router-link to="/"><i class="fas fa-home"></i></router-link>
              >&nbsp;&nbsp;
              <router-link :to="'/' + $i18n.locale + $t('rentalpath')">{{ $t('Rental') }}</router-link>
              >
              <router-link :to="'/' + $i18n.locale + $t('tenacypath') + building.slug">
                <b>[ {{ building.title }} ]</b>
              </router-link>              
            </div>
          </div>
        </div>
        <div>
          <div class="greybg">
            <div class="container">
              <div class="row facadeimage">
                <div id="lejlighed-container"
                     class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <carousel :perPage="1" :navigationEnabled="true" :paginationEnabled="false"
                            navigationNextLabel="<img src='/assets/images/whitearrow-right.png'>"
                            navigationPrevLabel="<img src='/assets/images/whitearrow-left.png'>">
                    <slide v-for="(mediaFile, rowId) in buildingMedia" :key="mediaFile">
                      <div v-if="mediaFile.mediaType == 'vimeoVideo'">
                        <div class="images-wrapper">
                          <img :src="mediaFile.image">
                          <a class="play-icon video" data-gallery="gallery" :key="rowId" @click="index2 = rowId"></a>
                        </div>
                      </div>
                      <div v-if="mediaFile.mediaType == 'image'">
                        <div class="images-wrapper">
                          <img :src="mediaFile.image">
                          <a class="image image-zoom" data-gallery="gallery" :key="rowId" @click="index2 = rowId"></a>
                        </div>
                      </div>         
                    </slide>
                  </carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-wrap hidden-md hidden-lg hidden-xl">
          <div :class="building.facadeBilledeBredde + ' clearfix nobottommargin container'">
            <div class="row">
              <div id="lejlighed-label" class="col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 clearfix pull-left">
                <div v-if="stateRent" class="fleft">
                  <p class="nobottommargin center">Til leje</p>
                  <p class="nobottommargin status" status-type="rent" status-availability="free"></p>
                </div>
                <div v-if="stateSales" class="fleft">
                  <p class="nobottommargin center">Til salg</p>
                  <p class="nobottommargin status" status-type="buy" status-availability="free"></p>
                </div>
                
                <div v-if="stateOnhold" class="fleft">
                  <p class="nobottommargin center">Reseveret</p>
                  <p class="nobottommargin status" status-availability="reserved"></p>
                </div>
                
                <div v-if="stateRentAndSold" class="fleft">
                  <p class="nobottommargin center">Udlejet</p>
                  <p class="nobottommargin status" status-type="rent" status-availability="occupied"></p>
                </div>
                
                <div v-if="stateSaleAndSoled" class="fleft">
                  <p class="nobottommargin center">Solgt</p>
                  <p class="nobottommargin status" status-type="buy" status-availability="occupied"></p>
                </div>
                
                <div v-if="stateSupply" class="fleft">
                  <p class="nobottommargin center">August 2019</p>
                  <p class="nobottommargin status" status-type="buy" status-availability="supply"></p>
                </div>
              </div>              
            </div>
            <div class="blackstroke hidden-md hidden-lg hidden-xl"></div>
          </div>
        </div>
        <div class="container">
          <div class="row mb-15 mt-20">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 hidden-xs hidden-sm breadcrumbs">
              <router-link to="/"><i class="fas fa-home"></i></router-link>
              >&nbsp;&nbsp;
              <router-link :to="'/' + $i18n.locale + $t('rentalpath')">{{ $t('Rental') }}</router-link>
              >
              <router-link :to="'/' + $i18n.locale + $t('tenacypath') + building.slug">
                <b>[ {{ building.title }} ]</b>
              </router-link>              
            </div>
          </div>
        </div>

        <div :class="'clearfix residentinfo ' + building.facadeBilledeBredde ">
          <div class="container">
            <div class="row hidden-xs hidden-sm rentalsgrid nomargin">
              <div class="col-md-12 col-lg-12 col-lg-12 nopadding headline"><h3>{{ $t('Other rentals on same address') }}</h3></div>             <div class="row">
              <div class="col-md-4 col-xl-4 col-lg-4"
                   v-for="(property, index) in properties"
                   :key="property" :id="index" @click="updatePropertyInfo">
                <div :id="index" class="rentalimage">
                  <div v-if="getAvailability(property.lejlighed_status) == 'reserved'"
                       :id="index" class="reserved rentallabel" >
                    <div class="text">{{ $t('Reserved label') }}</div>
                  </div>
                  <div v-if="getAvailability(property.lejlighed_status) == 'occupied'"
                       :id="index" class="occupied rentallabel">
                    <div class="text">{{ $t('Occupied label') }}</div>
                  </div>
                  <div v-if="getAvailability(property.lejlighed_status) == 'free'"
                       :id="index" class="free rentallabel">
                    <div class="text">{{ $t('Availalble label') }}</div>
                  </div>

                  <img :id="index" :src="property.apartment_images[0].urlSmall" v-if="property.apartment_images[0]">
                  <img :id="index" src="/assets/images/noimage-small-133.jpg" alt="ingen billede" v-if="!property.apartment_images[0]">
                </div>                
                <div class="rentaltext">{{ property.title }}</div>
              </div>
              </div>
            </div>
            <div id="lejlighed-table" class="hidden-lg hidden-xl hidden-md col_full">
              <table class="table">
                <tr>
                  <th>{{ $t('Rentals') }}</th>
                  <th>{{ $t('Size') }}</th>
                  <th class="text-center">{{ $t('State') }}</th>
                <tr v-for="(property, index) in properties"
                    :key="property" :id="index" @click="updatePropertyInfo">
                  <td :id="index">{{ property.bolignr }}</td>
                  <td :id="index">{{ property.strelse }}</td>
                  <td :id="index" class="status mobile"
                      :status-type="getStatus(property.lejligheds_type)"
                      :status-availability="getAvailability(property.lejlighed_status)"></td>
                </tr>
              </table>
            </div>
          </div>
          <div ref="targetRef">
          <div class="container" v-if="propertyData[0]">
            <div class="orangestroke hidden-xs hidden-sm"></div>
            <div class="col_full rentalStateBox">
              <div v-if="getAvailability(rentalState) == 'free'" class="rentalState free">{{ $t('Availalble label') }}</div>
              <div v-if="getAvailability(rentalState) == 'occupied'" class="rentalState occupied">{{ $t('Occupied label') }}</div>
              <div v-if="getAvailability(rentalState) == 'reserved'" class="rentalState reserved">{{ $t('Reserved label') }}</div>
            </div>
            <div id="lejlighed-billede" class="col_half">
              <div id="lejlighed-info" class="col_half hidden-md hidden-lg hidden-xl" style="padding-bottom:20px;">
              <h3>{{ $t('Information') }}</h3>
              <ul class="list-unstyled">             
                <li v-for="propertyInfo in propertyData" :key="propertyInfo">
                  <div v-if="propertyInfo.type === 'link'">
                    {{ apartment.name }}: <span class="pull-right">
                      <a :target="propertyInfo.target"
                         :alt="propertyInfo.linkalt"
                         :rel="propertyInfo.linkrel"
                         :href="propertyInfo.link">
                        {{ propertyInfo.value }}
                      </a>
                    </span>                    
                  </div>
                  <div v-if="propertyInfo.type != 'link'">
                    {{ propertyInfo.name }}: <span class="pull-right">
                      {{ propertyInfo.value }}
                    </span>             
                  </div>
                </li>
              </ul>
              <router-link :to="'/' + $i18n.locale + $t('tenacypath') + '/' + propertyLink">
                <button class="button button-border button-rounded button-large fleft">{{ $t('See rental') }}</button>
              </router-link>
            </div>
            
              <carousel :perPage="1" :navigationEnabled="false" :paginationEnabled="false"
                        navigationNextLabel="<img src='/assets/images/whitearrow-right.png'>"
                        navigationPrevLabel="<img src='/assets/images/whitearrow-left.png'>">
                <slide v-for="(image, id) in propertyImages" :key="image">
                  <div class="images-wrapper">
                    <img :src="image.urlMedium" :alt="image.title" :key="id">
                    <a class="image image-zoom" data-gallery="gallery" :key="id" @click="index = id"></a>
                  </div>
                </slide>
              </carousel>
            </div>
            <div id="lejlighed-info" class="col_half col_last hidden-xs hidden-sm">
              <h3>{{ $t('Information') }}</h3>
              <ul class="list-unstyled">             
                <li v-for="propertyInfo in propertyData" :key="propertyInfo">
                  <div v-if="propertyInfo.type === 'link'">
                    {{ apartment.name }}: <span class="pull-right">
                      <a :target="propertyInfo.target"
                         :alt="propertyInfo.linkalt"
                         :rel="propertyInfo.linkrel"
                         :href="propertyInfo.link">
                        {{ propertyInfo.value }}
                      </a>
                    </span>                    
                  </div>
                  <div v-if="propertyInfo.type != 'link'">
                    {{ propertyInfo.name }}: <span class="pull-right">
                      {{ propertyInfo.value }}
                    </span>             
                  </div>
                </li>
              </ul>
              <router-link :to="'/' + $i18n.locale + $t('tenacypath') + '/' + propertyLink">
                <button class="button button-border button-rounded button-large fleft">{{ $t('See rental') }}</button>
              </router-link>
            </div>
          </div>
          </div>
          <div class="container buildinginformation bottommargin">
            <div class="row">
              <div class="col-lg-12">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 text">
                  <h3>{{ $t('About property') }}</h3>
                  <span v-html="building.supplementaryBuildingText"></span>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 image">
                  <img v-if="building.extra"
                       :alt="building.extra_billede[0].title"
                       :src="building.extra_billede[0].url">                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <CoolLightBox :items="imageItems"
                  :index="index"
                  @close="index = null">
    </CoolLightBox>
    <CoolLightBox2 :items="buildingFacadeImages"
                  :index="index2"
                  @close="index2 = null">
    </CoolLightBox2>

    <Searchproperty v-bind:globalSet="globalSet"/>
    <Calltoactionbar v-bind:globalSet="globalSet"/>
  </div>


</template>

<script>
    import { Carousel, Slide } from 'vue-carousel';
    import { GET_BUILDINGS_QUERY } from '../graphql/buildingQueires'
    import { GET_PROPERTY_QUERY } from '../graphql/buildingQueires'
    import CoolLightBox from 'vue-cool-lightbox'
    import CoolLightBox2 from 'vue-cool-lightbox'
    import Searchproperty from './Searchproperty'
    import Calltoactionbar from './Calltoactionbar'
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

    export default {
    name: 'Buildinginfo',
    props: ['globalSet'],
        data() {
            return {
                imageItems: [],
                index: null,
                index2: null,
                buildingFacadeImages: [],
                buildingMedia: [],
                building: [],
                properties: [],
                associatedBuildingsEnabled: false,
                isModalVisible: false,
                propertyData: [],
                propertyImages: [],
                propertyLink: '',
                stateRent: false,
                stateSales: false,
                stateOnhold: false,
                stateRentAndSold: false,
                stateSaleAndSoled: false,
                stateSupply:false,
                rentalState: false
           }
        },
        watch: {
            $route () {
                this.fetchData()
            }
        },
        created() {
            this.fetchData()
        },
        components: {
            Carousel,
            Slide,
            CoolLightBox,
            CoolLightBox2,
            'Searchproperty': Searchproperty,
            'Calltoactionbar': Calltoactionbar
        },
        methods: {
            goto(url) {
                this.$router.push(url)
            },
            showModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
            getAvailability (availability) {
                switch(availability) {
                    case 'available':
                        return 'free'
                    case 'onhold':
                        return 'reserved'
                    case 'soled':
                        return 'occupied'
                    case 'supply':
                        return 'supply'
                    default:
                        return 'other'
                }
            },
            getStatus(status) {
                switch(status) {
                    case 'rent':
                        return 'rent'
                    case 'buy':
                        return 'buy'
                    default:
                        return 'other'
                }
            },
            fetchData () {
               var slug = this.$helpers.returnUrl(this.$router).substring(this.$helpers.returnUrl(this.$router).lastIndexOf('/') + 1)
               this.$apollo.query({
                    query: GET_BUILDINGS_QUERY,
                    variables: {
                        slug: slug,
                        siteId: this.$i18n.siteId
                    }
                }).then(function (response) {
                    if(response.data.building.length > 0) {
                       this.building = response.data.building[0]
                    }
                    if(this.building.id) {
                        this.fetchProperties(this.building.id)
                    }
                    for(let x=0; x < this.building.facade.length; x++) {
                        this.buildingFacadeImages[x] = this.building.facade[x].url
                    }
              if(this.building.buildingMedia.length > 0 ) {
                  this.buildingMedia = []
                  this.buildingFacadeImages = []
                  for(let i=0; i < this.building.buildingMedia.length; i++) {
                      if(this.building.buildingMedia[i].image.length > 0) {
                          var image = this.building.buildingMedia[i].image[0].url
                          var imageLarge = this.building.buildingMedia[i].image[0].urlLarge
       
      }

                      if(this.building.buildingMedia[i].mediatype == 'vimeoVideo') {
      image = 'https://industribo.dk/vimeoimage.php?id=' + this.building.buildingMedia[i].vimeoId + '&size=large';

                      }

                      let feature = {
                          mediaType: this.building.buildingMedia[i].mediatype,
                          vimeoId: this.building.buildingMedia[i].vimeoId,
                          image: image    
                      }
                      if(this.building.buildingMedia[i].mediatype == 'vimeoVideo') {
                          var src = 'https://player.vimeo.com/video/'
                                         + this.building.buildingMedia[i].vimeoId
                                         +'?quality=auto&autoplay=0&loop=0&autopause=0'
                      } else {
                          src = imageLarge
                      }
                      let lightboxItems = {
                          src: src
                      }
                      this.buildingFacadeImages[i] = lightboxItems
                      this.buildingMedia.push(feature)
                   }
               } else {
                      let lightboxItems = {
                          src: '/assets/images/noimage.jpg'
                      }

                      let feature = {
                          mediaType: 'image',
                          vimeoId: '',
                          image: '/assets/images/noimage.jpg'
                      }
                      this.buildingFacadeImages[0] = lightboxItems
                      this.buildingMedia.push(feature)                      
               }

                                     

                }.bind(this))
           },
           fetchProperties ($building) {
                this.$apollo.query({
                    query: GET_PROPERTY_QUERY,
                    variables: {
                        building: $building,
                        siteId: this.$i18n.siteId
                    }
                    }).then(function (response) {
                    if(response.data.property.length > 0) {
                        this.properties = response.data.property
                    }

                    for(let i=0; i < this.properties.length; i++) {
                        if(this.properties[i].lejligheds_type == 'rent') {
                            this.stateRent = true
                        }
                        if(this.properties[i].lejligheds_type == 'buy' &&
                           this.properties[i].lejlighed_status == 'available') {
                            this.stateBuy = true
                        }
                        if(this.properties[i].lejlighed_status == 'onhold') {
                            this.stateOnhold = true
                        }
                        if(this.properties[i].lejligheds_type == 'rent' &&
                           this.properties[i].lejlighed_status == 'soled') {
                            this.stateRentAndSold = true
                        }
                        if(this.properties[i].lejligheds_type == 'buy' &&
                           this.properties[i].lejlighed_status == 'soled') {
                            this.stateSaleAndSold = true
                        }
                        if(this.properties[i].lejlighed_status == 'supply') {
                            this.stateSupply = true
                        }
                    }
                }.bind(this))
           },
           updatePropertyInfo: function(event) {

               var propertyId = event.target.id
               var propertyData = this.properties[propertyId]
               this.propertyImages = propertyData.apartment_images
               this.propertyLink = propertyData.slug
               this.imageItems = []
               this.propertyData = []
               this.rentalState = propertyData.lejlighed_status
                                     
               for(let x=0; x < this.propertyImages.length; x++) {
                   this.imageItems[x] = { 'src': this.propertyImages[x].urlLarge }
               }
                   
               if(propertyData.boligtyper.length == 0) {
                   this.propertyData = [
                       { 'type': '', 'name': this.$i18n.t('Rental numbering'), 'value': propertyData.bolignr },
                       { 'type': '', 'name': this.$i18n.t('Gross area'), 'value': propertyData.strelse.toLocaleString('da-DK') }
                   ]
               } else {
                   this.propertyData = [
                       { 'type': '', 'name': this.$i18n.t('Rental numbering'), 'value': propertyData.bolignr },
                       { 'type': '', 'name': this.$i18n.t('Tenancy type'), 'value': propertyData.boligtyper[0].title },
                       { 'type': '', 'name': this.$i18n.t('Gross area'), 'value': propertyData.strelse.toLocaleString('da-DK') + ' m²' }
                   ]
               }

               for(let i=0; i < propertyData.information.length; i++) {
                  var property = { 'type': propertyData.information[i].info_type,
                                   'name': propertyData.information[i].info_name,
                                   'value': propertyData.information[i].info_value,
                                   'link': propertyData.information[i].info_link,
                                   'target': propertyData.information[i].info_linktarget,
                                   'linkrel': propertyData.information[i].info_linkrel,
                                   'linkalt': propertyData.information[i].info_linkalt
                  }
                  this.propertyData.push(property)
                                }
                                this.$refs.targetRef.scrollIntoView({ behavior: 'smooth' });
          }
        }
     }

</script>

<style>
</style>
