<template>
<div>
<div v-if="!loggedIn">
  <section id="page-title">
    <div class="container clearfix">
      <h1>{{ $t('Tenant login') }}</h1>
    </div>
  </section>
  
  <section id="content">
    <div class="content-wrap">
      <div class="container clearfix">
        <div class="col_full residentlogin">
          <form accept-charset="UTF-8" @submit.prevent="doLogin">
            <div class="errors" v-if="errors">
              {{ errors }}
              </div>
            <input type="hidden" :name="csrfName" :value="csrfToken">
            <div class='hiddenFields'>
              
            </div>
              <p>
		<label>{{ $t('Username') }}</label><br>
                <input id="loginName" type="text" name="loginName" value="" v-model="theUser.loginName">
              </p>
            <p>
              <label>{{ $t('Password') }}</label><br>
              <input id="password" type="password" name="password" value="" v-model="theUser.password">
            </p>
            <p><button class="button button-border button-rounded button-large" type="submit" name="submit">{{ $t('Login') }}</button></p>
          </form>		
        </div>
      </div>
    </div>
  </section>
</div>
<div v-if="loggedIn">
    <Residentinfo v-bind:theUser="theUser"/>
</div>
</div>
</template>

<script>
  import Residentinfo from './Residentinfo'

  export default {
  name: 'residentlogin',
  data () {
      return {
          theUser: {
              loginName: null,
              password: null,
              action: 'users/login',
              returnUrl: '/lejerinformation'
           },
           loggedIn: false,
           errors: null,
           message: null,
      }
  },
  components: {
      'Residentinfo': Residentinfo
  },
  computed: {
      csrfName () {
          return window.csrfTokenName
      },
      csrfToken () {
          return window.csrfTokenValue
      }
  },
  methods: {
      doLogin () {
          let data = this.theUser
	
          this.$http.post('/', data)
          .then(function (response) {
              console.log(response)
              if (response.body.success) {
                   this.loggedIn = true
              }
              if (response.body.error) {
                  this.errors = response.body.error
              }
          })
      }
  }
}
</script>

<style>
</style>
