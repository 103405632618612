<template>
<div>
  <div id="page-title" v-if="loadingDone">
      <div class="container">
        <h1 class="building-info" style="float:left;" v-if="loadingDone">{{ rental.bolig_blok[0].title }}</h1>
      </div>
    </div>
    
    <div id="content">
      <div class="content-wrap">
        <div class="container buildingheadline">
          <div class="col_full nomargin">
            <div class="col_full nomargin ">
              <h2 v-if="loadingDone">{{ rental.rentalHeadline }}</h2>
              <div class="stroke"></div>
            </div>
          </div>
          <div class="col_full mb-30">
            <div class="col_full nomargin">
              <h3 v-if="rental.strelse">{{ $t('Area size') }} {{ rental.strelse.toLocaleString('da-DK')}} m²</h3>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row padding-bottom-25 hidden-lg hidden-md">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12breadcrumbs">
              <router-link to="/"><i class="fas fa-home"></i></router-link>
              >&nbsp;&nbsp;
              <router-link :to="'/' + $i18n.locale">{{ $t('Rental') }}</router-link>
              >
              <router-link v-if="loadingDone" :to="'/' + $i18n.locale + $t('rentalpath') + '/' + rental.bolig_blok[0].slug">
                {{ rental.bolig_blok[0].title }}
              </router-link>
              >
              <router-link :to="'/' + $i18n.locale + $t('tenacypath') + '/' + rental.slug">
                <b>[ {{ rental.title }} ]</b>
              </router-link>              
            </div>
          </div>
        </div>
        <div>
          <div class="greybg">
            <div class="container">
              <div class="row facadeimage" v-if="loadingDone">
                <div id="lejlighed-container" class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <img
                       :alt="coverImageTitle"
                       :src="coverImage" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rentalinfo">
          <div class="container">
            <div class="row mb-15">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 hidden-xs hidden-sm breadcrumbs">
                <router-link :to="'/' + $i18n.locale"><i class="fas fa-home"></i></router-link>
                >
                <router-link :to="'/' + $i18n.locale + $t('rentalpath')">{{ $t('Rental') }}</router-link>
                >
                <router-link v-if="loadingDone" :to="'/' + $i18n.locale + $t('buildingpath') + '/' + rental.bolig_blok[0].slug">
                  {{ rental.bolig_blok[0].title }}
                </router-link>
                >
                <router-link :to="'/' + $i18n.locale + $t('tenacypath') + '/' + rental.slug">
                 <b>[ {{ rental.title }} ]</b>
                </router-link>
                
              </div>
            </div>
            <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 nomargin"><h3>{{ rental.title }}</h3></div>
            <div class="col-xl-6 col-lg-6 col-md-6 hidden-xs hidden-sm nomargin tright"><h3>{{ $t('Rental number') }} <span class="orange">{{ rental.bolignr }}</span></h3></div>
            </div>
            <div class="row">
              <div class="col-xs-6 col-sm-6 hidden-md hidden-lg hidden-xl">{{ $t('Rental number') }}</div>
              <div class="col-xs-6 col-sm-6 hidden-md hidden-lg hidden-xl text-right"><span class="orange">{{ rental.bolignr }}</span></div>
            </div>
          </div>
          <div class="container rentalStateBox">
              <div v-if="getAvailability(rental.lejlighed_status) == 'free'" class="rentalState free">{{ $t('Available label') }}</div>
              <div v-if="getAvailability(rental.lejlighed_status) == 'occupied'" class="rentalState occupied">{{ $t('Occupied label') }}</div>
              <div v-if="getAvailability(rental.lejlighed_status) == 'reserved'" class="rentalState reserved">{{ $t('Reserved label') }}</div>
          </div>
          <div class="container rentaldata" v-if="propertyData">
            <div id="lejlighed-info" class="col_half ">
              <div class="col_full nomargin">
                <div style="float:left;"><h3 v-if="getAvailability(rental.lejlighed_status) == 'free'">{{ $t('Economy headline') }}</h3></div>
                <div style="float:left;">&nbsp;&nbsp; - {{ $t('Price ex. vat') }}</div>
              </div>
              <div class="col_full">
              <ul v-if="getAvailability(rental.lejlighed_status) == 'free'" class="list-unstyled">             
                <li v-if="rental.rent">
                  <div>{{ $t('Monthly rent') }}</div>
                  <div>Kr. {{ rental.rent.toLocaleString('da-DK') }},-</div>
                </li>
                <li v-if="rental.consumption">
                  <div>{{ $t('Monthly consumption') }}</div>
                  <div>Kr. {{ rental.consumption.toLocaleString('da-DK') }},-</div>
                </li>
                <li v-if="rental.deposit">
                  <div>{{ $t('Security deposit') }}</div>
                  <div>Kr. {{ rental.deposit.toLocaleString('da-DK') }},-</div>
                </li>
                <li></li>
                <li v-if="rental.yearlyRentPrSquareMeter">
                  <div>{{ $t('Yearly rent pr. squaremeter') }}</div>
                  <div>Kr. {{ rental.yearlyRentPrSquareMeter.toLocaleString('da-DK') }},-</div>
                </li>
                <li v-if="rental.yearlyRentInclOpreationCost">
                  <div>{{ $t('Yearly rent incl. operation cost') }}</div>
                  <div>Kr. {{ rental.yearlyRentInclOpreationCost.toLocaleString('da-DK') }},-</div>
                </li>
                <li v-if="rental.yearlyRentInclOpreationCostPrSquaremeter">
                  <div>{{ $t('Yearly rent incl. operation cost pr. squaremeter') }}</div>
                  <div>Kr. {{ rental.yearlyRentInclOpreationCostPrSquaremeter.toLocaleString('da-DK') }},-</div>
                </li>
                <li v-if="rental.yearlyOperationCost"> 
                  <div>{{ $t('Yearly operation cost') }}</div>
                  <div>Kr. {{ rental.yearlyOperationCost.toLocaleString('da-DK') }},-</div>
                </li>
                <li v-if="rental.yearlyOperationCostPrSquareMeter">
                  <div>{{ $t('Yearly operation cost pr. squaremeter') }}</div>
                  <div>Kr. {{ rental.yearlyOperationCostPrSquareMeter.toLocaleString('da-DK') }},-</div>
                </li>                
              </ul>              
              <h3>{{ $t('Rental data') }}</h3>
              <ul class="list-unstyled">             
                <li v-for="propertyInfo in propertyData" :key="propertyInfo">
                  <div v-if="propertyInfo.type === 'link'">
                    {{ apartment.name }}:
                    <span class="pull-right" v-if="propertyInfo.value">
                      <a :target="propertyInfo.target"
                         :alt="propertyInfo.linkalt"
                         :rel="propertyInfo.linkrel"
                         :href="propertyInfo.link">
                        {{ propertyInfo.value.toLocaleString('da-DK')}}
                      </a>
                    </span>                    
                  </div>
                  <div v-if="propertyInfo.type === 'link'">
                      <a :target="propertyInfo.target"
                         :alt="propertyInfo.linkalt"
                         :rel="propertyInfo.linkrel"
                         :href="propertyInfo.link"
                         v-if="propertyInfo.value">
                        {{ propertyInfo.value.toLocaleString('da-DK')}}
                      </a>
                    </div>
                  <div v-if="propertyInfo.type != 'link'">
                    {{ propertyInfo.name }}
                  </div>
                  <div v-if="propertyInfo.type != 'link'">
                      {{ propertyInfo.value.toLocaleString('da-DK') }}
                  </div>             
                </li>
              </ul>

              <h3>{{ $t('Facilities') }}</h3>
              <ul class="list-unstyled">             
                <li v-for="facilities in propertyFacilities" :key="facilities">
                  <div v-if="facilities.type === 'link'">
                    {{ apartment.name }}:
                    <span class="pull-right">
                      <a :target="facilities.target"
                         :alt="facilities.linkalt"
                         :rel="facilities.linkrel"
                         :href="facilities.link">
                        {{ facilities.value.toLocaleString('da-DK')}}
                      </a>
                    </span>                    
                  </div>
                  <div v-if="facilities.type === 'link'">
                      <a :target="facilities.target"
                         :alt="facilities.linkalt"
                         :rel="facilities.linkrel"
                         :href="facilities.link">
                        {{ facilities.value.toLocaleString('da-DK')}}
                      </a>
                    </div>
                  <div v-if="facilities.type != 'link'">
                    {{ facilities.name }}
                  </div>
                  <div v-if="facilities.type != 'link'">
                      {{ facilities.value.toLocaleString('da-DK')}}
                  </div>             
                </li>
              </ul>
              <div class="row">
              <div v-if="loadingDone" style="margin-bottom:10px;" class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"><button v-if="rental.floorPlan.length" @click="showModalDesc" class="button button-border button-rounded button-large">{{ $t('Show description') }}</button></div>
              
              <div v-if="loadingDone" class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"><button v-if="rental.floorPlan.length" @click="showModal" class="button button-border button-rounded button-large">{{ $t('Show floorplan') }}</button></div>
              </div>

            </div>
            </div>
            <div id="lejlighed-billede" class="col_half col_last">
              <carousel v-if="rentalMedia.length > 0" :perPage="1" :navigationEnabled="true" :paginationEnabled="false"
                        navigationNextLabel="<img src='/assets/images/whitearrow-right.png'>"
                        navigationPrevLabel="<img src='/assets/images/whitearrow-left.png'>">
                
                <slide v-for="(mediaFile, rowId) in rentalMedia" :key="mediaFile">
                  <div v-if="mediaFile.mediaType == 'vimeovideo'">
                    <div class="images-wrapper">
                      <img :src="mediaFile.image">
                      <a class="play-icon video" data-gallery="gallery" :key="rowId" @click="index2 = rowId"></a>
                    </div>
                  </div>
                  <div v-if="mediaFile.mediaType == 'image'">
                    <div class="images-wrapper">
                      <img :src="mediaFile.image">
                      <a class="image image-zoom" data-gallery="gallery" :key="rowId" @click="index2 = rowId"></a>
                    </div>
                  </div>         
                </slide>                               
              </carousel>
              <div v-if="rentalMedia.length == 0">
                <img src="/assets/images/noimage.jpg" alt="ingen billede">
              </div>
            </div>
          </div>
          <div class="container">

            <div class="sharebar">
              <div class="si-share col_half col_last float-right nopadding">
                <div style="float:left;">
                  <button @click="print()" class="btn btn-default"><i class="fa fa-print"></i> {{ $t('Print images') }}</button>
                </div>
                <div>
                  <div style="float:left; font-weight: 600; padding-top: 6px;">{{ $t('Share') }}:</div>
                  <div>
                    <ShareNetwork class="social-icon si-borderless si-facebook" network="facebook" :url='currentUrl'>
                      <i class="icon-facebook"></i>
                      <i class="icon-facebook"></i>
                    </ShareNetwork>
                    <ShareNetwork class="social-icon si-borderless si-twitter" network="twitter" :url='currentUrl'>
                      <i class="icon-twitter"></i>
                      <i class="icon-twitter"></i>
                    </ShareNetwork>
                    <ShareNetwork class="social-icon si-borderless si-pinterest" network="pinterest" :url='currentUrl'>
                      <i class="icon-pinterest"></i>
                      <i class="icon-pinterest"></i>
                    </ShareNetwork>
                    <ShareNetwork class="social-icon si-borderless si-linkedin" network="linkedin" :url='currentUrl'>
                      <i class="icon-linkedin"></i>
                      <i class="icon-linkedin"></i>
                    </ShareNetwork>
                  </div>
                </div>
              </div>              
            </div>
          </div>
          <div class="container">
            <div class="blackstroke bottommargin"></div>
          </div>
          <div class="container">
            <h2>{{ $t('Sign up for interestlist') }}</h2>
            <StandbylistForm v-bind:rental="rental.bolignr" v-bind:rentalTitle="rental.title" v-if="loadingDone"/>
          </div>
          <div class="container" v-if="relatedRentals">
            <div class="blackstroke bottommargin"></div>
          </div>
          <div class="container" v-if="relatedRentals">
            <div id="lejlighed-table" class="hidden-lg hidden-xl hidden-md col_full">
              <table class="table">
                <tr>
                  <th>{{ $t('Rentals') }}</th>
                  <th>{{ $t('Size') }}</th>
                  <th class="text-center">{{ $t('State') }}</th>
                <tr v-for="(property, index) in relatedRentals"
                    :key="property" :id="index">

                  <td :id="index"><router-link :to="'/' + $i18n.locale + $t('tenacypath') + property.slug">{{ property.title }}</router-link></td>
                  <td :id="index" v-if="property.strelse">{{ property.strelse }} m²</td>
                  <td :id="index" class="status mobile"
                      :status-type="getStatus(property.lejligheds_type)"
                      :status-availability="getAvailability(property.lejlighed_status)"></td>
                </tr>
              </table>
            </div>
            <div class="row hidden-xs hidden-sm rentalsgrid nomargin">
              <div class="col-md-12 col-lg-12 col-lg-12 nopadding headline"><h3>{{ $t('Other available rentals') }}</h3></div>
              <div class="col-md-4 col-xl-4 col-lg-4"
                   v-for="(property, index) in relatedRentals"
                   :key="property" :id="index">
                <router-link :to="'/' + $i18n.locale + $t('tenacypath') + '/' + property.slug">
                  <div :id="index" class="rentalimage">
                    <img :id="index" :src="property.apartment_images[0].url" v-if="property.apartment_images[0]">
                    <img :id="index" src="/assets/images/noimage-small-133.jpg" alt="ingen billede" v-if="!property.apartment_images[0]">
                    <div v-if="getAvailability(property.lejlighed_status) == 'reserved'"
                         :id="index" class="reserved rentallabel" >
                      <div class="text">{{ $t('Reserved label') }}</div>
                    </div>
                    <div v-if="getAvailability(property.lejlighed_status) == 'occupied'"
                         :id="index" class="occupied rentallabel">
                      <div class="text">{{ $t('Occupied label') }}</div>
                    </div>
                    <div v-if="getAvailability(property.lejlighed_status) == 'free'"
                       :id="index" class="free rentallabel">
                      <div class="text">{{ $t('Availalble label') }}</div>
                    </div>
                  </div>
                  </router-link>
                <div class="rentaltext">{{ property.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CoolLightBox2 :items="rentalImages"
                   :index="index2"
                   @close="index2 = null">
    </CoolLightBox2>
    <CoolLightBox :items="imageItems"
                  :index="index"
                  @close="index = null">
    </CoolLightBox>

    <Searchproperty v-bind:globalSet="globalSet"/>
    <Calltoactionbar v-bind:globalSet="globalSet"/>
    <modal
      v-if="loadingDone"
      v-show="isModalVisible"
      @close="closeModal"
      v-bind:headline="$t('Floorplan')"
      v-bind:show="'image'"
      v-bind:text="rental.propertyDescription"
      v-bind:image="rental.floorPlan[0]"
      />
        <modal
      v-if="loadingDone"
      v-show="isModalVisibleDesc"
      @close="closeModalDesc"
          v-bind:headline="$t('Description')"
          v-bind:show="'desc'"
      v-bind:text="rental.propertyDescription"
      v-bind:image="rental.floorPlan[0]"
      />

    <PrintImages v-bind:rental="rental"
                 v-bind:rentalMedia="rentalMedia"
                 v-bind:globalSet="globalSet"
                 id="printMe"
                 class="hidden"/>
    <div class="vld-parent">
      <Loading :active.sync="isLoading"
               :can-cancel="true"
               :is-full-page="fullPage"
               :color="loadingColor"
               ></Loading>
    </div>
</div>

</template>

<script>
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css'
    import { Carousel, Slide } from 'vue-carousel';
    import { GET_SPECIFIC_PROPERTY_QUERY } from '../graphql/buildingQueires'
    import CoolLightBox from 'vue-cool-lightbox'
    import CoolLightBox2 from 'vue-cool-lightbox'
    import { siteId } from '../generalSettings.js'
    import Searchproperty from './Searchproperty'
    import Calltoactionbar from './Calltoactionbar'
    import StandbylistForm from './StandbylistForm'
    import modal from './Modal'
    import PrintImages from './PrintImages'

    export default {
    name: 'Buildinginfo',
    props: ['globalSet'],
        data() {
            return {
                output:null,
                coverImage: null,
                coverImageTitle: null,
                imageItems: [],
                rentalImages: [],
                index: null,
                index2: null,
                rental: [],
                associatedBuildingsEnabled: false,
                isModalVisible: false,
                isModalVisibleDesc:false,
                rentalMedia: [],
                propertyData: [],
                propertyFacilities: [],
                propertyImages: [],
                relatedRentals: null,
                loadingDone: 0,
                loadPdf: false,
                currentUrl: '',
                isLoading: false,
                loadingColor: '#f89520',
                fullPage: true,
             }
        },
        watch: {
            $route () {
                this.fetchData()
            },
        },
        created() {
            this.currentUrl = window.location.href
            this.fetchData()
        },
        components: {
             Loading,
             Carousel,
             Slide,
             modal,
             CoolLightBox,
             CoolLightBox2,
             PrintImages,
            'Searchproperty': Searchproperty,
            'Calltoactionbar': Calltoactionbar,
            'StandbylistForm': StandbylistForm
        },
        methods: {
            goto(url) {
                this.$router.push(url)
            },
            print () {
               this.$htmlToPaper('printMe')
            },
            showModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
            showModalDesc() {
                this.isModalVisibleDesc = true;
            },
            closeModalDesc() {
                this.isModalVisibleDesc = false;
            },

            getAvailability (availability) {
                switch(availability) {
                    case 'available':
                        return 'free'
                    case 'onhold':
                        return 'reserved'
                    case 'soled':
                        return 'occupied'
                    case 'supply':
                        return 'supply'
                    default:
                        return 'other'
                }
            },
            getStatus(status) {
                switch(status) {
                    case 'rent':
                        return 'rent'
                    case 'buy':
                        return 'buy'
                    default:
                        return 'other'
                }
            },
            fetchData () {
                this.isLoading = true;
                var slug = this.$helpers.returnUrl(this.$router).substring(this.$helpers.returnUrl(this.$router).lastIndexOf('/') + 1)

                this.$apollo.query({
                    query: GET_SPECIFIC_PROPERTY_QUERY,
                        variables: {
                            slug: slug,
                            siteId: this.$i18n.siteId
                        }
                     }).then(function (response) {
                        this.rental = []
                        this.rental = response.data.property[0]
                        if( response.data.property[0].relatedRentals.length > 0 ) {
                             this.relatedRentals = response.data.property[0].relatedRentals
                        } else {
                             this.relatedRentals = null
                        }

                        this.updatePropertyInfo()
                        this.loadingDone = true
                        this.isLoading = false;
               }.bind(this))
           },
           updatePropertyInfo: function() {
        
                   this.propertyImages = this.rental.apartment_images
                   this.rentalMedia = []
                   this.coverImage = ''
                   this.coverImageTitle = ''

                   for(let x=0; x < this.propertyImages.length; x++) {
                         this.imageItems[x] = this.propertyImages[x].url
                   }
                   var propertyData = this.rental

                   if(propertyData.boligtyper.length == 0) {
                       this.propertyData = [
                           { 'type': '', 'name': this.$i18n.t('Rental numbering'), 'value': propertyData.bolignr },
                           { 'type': '', 'name': this.$i18n.t('Gross area'), 'value': propertyData.strelse.toLocaleString('da-DK') + ' m²' }
                       ]
                   } else {
                       this.propertyData = [
                           { 'type': '', 'name': this.$i18n.t('Rental numbering'), 'value': propertyData.bolignr },
                           { 'type': '', 'name': this.$i18n.t('rentaltype'), 'value': propertyData.boligtyper[0].title },
                           { 'type': '', 'name': this.$i18n.t('Gross area'), 'value': propertyData.strelse.toLocaleString('da-DK') + ' m²' }
                       ]
                   }
                   if(propertyData.rentalMedia.length > 0 ) {
                       for(let i=0; i < propertyData.rentalMedia.length; i++) {
                           if(propertyData.rentalMedia[i].image.length > 0) {
                               var image = propertyData.rentalMedia[i].image[0].url
                               var largeImage = propertyData.rentalMedia[i].image[0].urlLarge
                               var title = propertyData.rentalMedia[i].image[0].title
                           }
                           if(propertyData.rentalMedia[i].mediaType == 'vimeovideo') {
                               image = 'https://industribo.dk/vimeoimage.php?id=' + propertyData.rentalMedia[i].vimeoId + '&size=small';
                           }
                           let feature = {
                               mediaType: propertyData.rentalMedia[i].mediaType,
                               vimeoId: propertyData.rentalMedia[i].vimeoId,
                               image: image,
                               autoplay: true,
                               title: title
                           }
                           if(propertyData.rentalMedia[i].mediaType == 'vimeovideo') {
                               var src = 'https://player.vimeo.com/video/'
                                         + propertyData.rentalMedia[i].vimeoId
                                         //+'?quality=auto&autoplay=1&loop=0&autopause=0'

                           } else {
                               src = largeImage
                           }
                           let lightboxItems = {
                                src: src,
                                autoplay: true
                           }
                           if(propertyData.rentalMedia[i].primary &&
                              propertyData.rentalMedia[i].mediaType == 'image') {
                              this.coverImage = propertyData.rentalMedia[i].coverImage[0].url
                              this.coverImageTitle = propertyData.rentalMedia[i].coverImage[0].title
                           }

                           this.rentalImages[i] = lightboxItems
                           this.rentalMedia.push(feature)
                       }

                   }
                   if(!this.coverImage) {
                       this.coverImage = '/assets/images/noimage.jpg'
                       this.coverImageTitle = 'Ingen billede tilgængelig'
                   }
                        for(let i=0; i < propertyData.information.length; i++) {
                            if(propertyData.information[i].information_type == 'rental'
                                         || propertyData.information[i].information_type == '') {
                                         if(!propertyData.information[i].info_value) {
                                             propertyData.information[i].info_value = ''
                                         }
                                var property = { 'type': propertyData.information[i].info_link,
                                    'name': propertyData.information[i].info_name,
                                    'value': propertyData.information[i].info_value,
                                    'link': propertyData.information[i].info_link,
                                    'target': propertyData.information[i].info_linktarget,
                                    'linkrel': propertyData.information[i].info_linkrel,
                                    'linkalt': propertyData.information[i].info_linkalt
                                }
                                this.propertyData.push(property)
                            }
                            if(propertyData.information[i].information_type == 'facilities') {
                               var facilities = { 'type': propertyData.information[i].info_link,
                                   'name': propertyData.information[i].info_name,
                                   'value': propertyData.information[i].info_value,
                                   'link': propertyData.information[i].info_link,
                                   'target': propertyData.information[i].info_linktarget,
                                   'linkrel': propertyData.information[i].info_linkrel,
                                   'linkalt': propertyData.information[i].info_linkalt
                               }
                               this.propertyFacilities.push(facilities)
                           }

                       }
           }
       }
     }

</script>

<style>
</style>
