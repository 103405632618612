import Vue from 'vue';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import VueApollo from 'vue-apollo';
import { backendUrl,apiKey } from '../generalSettings'

const getHeaders = () => {
    const headers = {};
    headers.authorization = 'Bearer ' + apiKey;
    return headers;
};

const httpLink = createHttpLink({
    uri: backendUrl + '/api',
    headers: getHeaders()
})

const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
    link: httpLink,
    cache: cache,
    connectToDevTools: true,
});

Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
