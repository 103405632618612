<template>
  <div>
    <form :id="'contact_' + gaevent" v-on:submit="onSubmit">
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <div v-if="submiterror"><p class="alert alert-danger">{{ submiterror }}</p></div>
        </div>
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-firstName" class="required">{{ $t('Name') }}</label>
          <input class="form-control" v-model="firstName" name="firstName" type="text" id="form-input-firstName" :placeholder="$t('Name label')">
          <ul v-if="getErrors('firstName').length" class="errors">
            <li v-for="error in getErrors('firstName')" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-Postnr-by" class="required">{{ $t('Zipcode') }}</label>
          <input class="form-control" v-model="zipCode" name="zipCode" type="text" id="form-input-Postnr-by" :placeholder="$t('Zipcode label')">
          <ul v-if="getErrors('zipCode').length" class="errors">
            <li v-for="error in getErrors('zipCode')" :key="error">
              {{ error }}
            </li>
          </ul>          
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-email" class="required">{{ $t('Email') }}</label>
          <input class="form-control" v-model="email" name="email" type="email" id="form-input-email" :placeholder="$t('Email label')">
          <ul v-if="getErrors('email').length" class="errors">
            <li v-for="error in getErrors('email')" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-cellPhone" class="required">{{ $t('Phone') }}</label>
          <input class="form-control" v-model="cellPhone" name="cellPhone" type="text" id="form-input-cellPhone" :placeholder="$t('Phone label')">
          <ul v-if="getErrors('cellPhone').length" class="errors">
            <li v-for="error in getErrors('cellPhone')" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-message" class="">{{ $t('Comment') }}</label>
          <textarea class="form-control" v-model="message" name="message" id="form-input-message" rows="5" :placeholder="$t('Comment label')"></textarea>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-boligtype">{{ $t('Where did you hear about industribo?') }}</label>
          <select class="form-control" v-model="hvorHarDuHrtOmProjektet" name="hvorHarDuHrtOmProjektet" id="form-input-hvorHarDuHrtOmProjektet">
            <option value="LinkedIn">LinkedIn</option>
            <option value="Outdoor reklame">Outdoor Reklame</option>            
            <option value="Ejendomstorvet">Ejendomstorvet</option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Søgt på nettet">{{ $t('Search online') }}</option>
            <option value="Andet">{{ $t('Other') }}</option>
          </select>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <vue-recaptcha
            ref="recaptcha"
            @verify="onRecaptchaVerified"
            :sitekey="yourRecaptchaSiteKey"
            ></vue-recaptcha>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12 submit-align-left">
          <button class="button button-border button-rounded button-large" type="submit" name="form_page_submit">{{ $t('Contact us') }}</button>
        </div>
      </div>
    </form>

      <div class="vld-parent">
    <Loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="fullPage"
             :color="loadingColor"
             ></Loading>
  </div>
  </div>
</template>

<script>
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'
  import { backendUrl, formHash } from '../generalSettings'
  import VueRecaptcha from 'vue-recaptcha';

  export default {
  name: 'Contact form',
  props: ['gaevent'],
  delimiters: ['${', '}'],
  data() {
    return {
      yourRecaptchaSiteKey: '6LdTqz8pAAAAAA10rC9NTA-L5_rYqiq_GwWxFgv_',
      isLoading:false,
      fullPage: true,
      loader: 'dots',
      loadingColor: '#f89520',
      loadingOpacity: 0.5,
      firstName: '',
      email: '',
      zipCode: '',
      cellPhone: '',
      message: '',
      hvorHarDuHrtOmProjektet: '',
      errormessage: 'Feltet skal udfyldes',
      submiterror: '',
      errors: [],
      formSettings: []
   }
  },
  components: {
  Loading,
  VueRecaptcha
  },
  computed: {
      csrfName () {
          return window.csrfTokenName
      },
      csrfToken () {
          return window.csrfTokenValue
      }
  },
  created() {
     this.getJson()
  },
  methods: {
      getErrors: function (property) {
          const errors = [];
          if (!this.errors.length) {
              return errors;
           }
           for (let i = 0; i < this.errors.length; i++) {
              if (this.errors[i].key === property) {
                  errors.push(this.errors[i].message);
              }
           }
           return errors;
      },
      getJson: function() {
           var requestUrl = backendUrl + '/contactformda.html'
           if(this.$i18n.siteId == 1) {
               requestUrl = backendUrl + '/contactformda.html'
           } else if(this.$i18n.siteId == 4) {
               requestUrl = backendUrl + '/contactformen.html'
           } else if(this.$i18n.siteId == 3) {
               requestUrl = backendUrl + '/contactformde.html'
           }                            
           fetch(requestUrl)
               .then(r => r.json())
               .then(json => { this.formSettings = json; })
      },
      onRecaptchaVerified(token) {
      // Handle the token, you can send it to the server for verification
      //console.log('Recaptcha Token:', token);
      },
      validate: function (property) {
        if (!property) {
          this.errors = [];
        } else {
          this.errors = this.errors.filter((item) => item.key !== property);
        }
        if ((!property || property === 'firstName') && !this.firstName) {
          this.errors.push({ key: 'firstName', message: this.errormessage });
        }
        if ((!property || property === 'email') && !this.email) {
          this.errors.push({ key: 'email', message: this.errormessage });
        }
        if ((!property || property === 'zipCode') && !this.zipCode) {
          this.errors.push({ key: 'zipCode', message: this.errormessage });
        }
        if ((!property || property === 'cellPhone') && !this.cellPhone) {
          this.errors.push({ key: 'cellPhone', message: this.errormessage });
        }
      },
      onSubmit: function (evt) {
        this.validate();
        if (this.errors.length) {
         evt.stopPropagation();
         evt.preventDefault();
          return false;
        }
        this.$refs.recaptcha.execute()
        this.isLoading = true;
        const form = evt.target;
        const data = new FormData(form);
        const request = new XMLHttpRequest();

        // Add Honeypot field
        data.append('freeform_form_handle', '');

        // Add action which handles freeform validation
        data.append('action', 'freeform/submit');
        data.append('freeform-action', 'submit');


        // Add the form's hash, so that it gets recognized by the form service
        data.append('formHash', this.formSettings.hash);
        data.append(this.formSettings.csrf.name, this.formSettings.csrf.value);
        data.append('freeform_payload', this.formSettings.payload);


        // Append the data
        data.append('firstName', this.firstName);
        data.append('zipCode', this.zipCode);
        data.append('email', this.email);
        data.append('cellphone', this.cellPhone);
        data.append('message', this.message);
        data.append('hvorHarDuHortOmIndustribo', this.hvorHarDuHrtOmProjektet);
        data.append(this.csrfName, this.csrfToken)
    
        // Create a request
        request.open('POST', backendUrl, true);
        request.setRequestHeader("Cache-Control", "no-cache");

        // Add the AJAX headers specific to Craft CMS
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest");

        // Attach the response handler callback
        request.onload = () => {

        this.isLoading = false

//        this.$gtm.trackEvent({
//            event: 'interaction',
//            category: 'Ventelist',
//            action: 'Tilmeld',
//            label: this.gaevent,
//            value: 1000
//        });

        if (request.status === 200) {
            const response = JSON.parse(request.response);
            const { success, finished, errors, formErrors } = response
            if (success && finished) {
              // Reset the form so that the user may enter fresh information
              form.reset();
              this.submiterror = ''
            this.$router.push(response.returnUrl)
            } else if (errors || formErrors) {
                this.submiterror = this.$i18n.t('An error occurred. Please check that everything is filled out correctly and try again')
            }
          }
        };

        // Send the request
        request.send(data);

        evt.stopPropagation();
        evt.preventDefault();
        return false;
      },
    },
  }

</script>

<style>
</style>
