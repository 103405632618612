<template>
  <div>
    <section id="page-title">
      <div class="container clearfix">
	<h1>{{ $t('Contactpage headline') }}</h1>
      </div>
    </section>
    <Contentrows v-bind:gmap="gmap"
                 v-bind:globalSet="globalSet"/>
  </div>
</template>

<script>
  import ContentpageContentrows from './ContactpageContentrows'
  import { GET_CONTENTPAGE_QUERY } from '../graphql/contentrowsQueries';

  export default {
     name: 'Contentpage',
     props: ['globalSet'],
     components: {
         Contentrows: ContentpageContentrows
     },
     data() {
         return {
             gmap: []
         }
     },
     created() {
         this.fetchData()
     },
     watch: {
         $route () {
             this.gmap = []
             this.fetchData()
         }
     },
     methods: {
        fetchData () {
              var slug = this.$helpers.returnUrl(this.$router).substring(this.$helpers.returnUrl(this.$router).lastIndexOf('/') + 1)
             this.$apollo.query({
                 query: GET_CONTENTPAGE_QUERY,
                 variables: {
                     "slug": slug,
                     siteId: this.$i18n.siteId
                 }
             }).then(function (response) {
                 if(response.data.gmap[0]) {
                     this.gmap = response.data.gmap[0]
                 }
             }.bind(this))
         }
     }
   }
</script>

<style>
</style>
