import gpl from 'graphql-tag'

export const GET_SEOMATIC_TAGS = gpl`
query($uri:String, $siteId:Int) {
  seomatic(uri: $uri, siteId: $siteId) {
      metaTitleContainer
      metaTagContainer
      metaLinkContainer
      metaScriptContainer
      metaJsonLdContainer
  }
}`
