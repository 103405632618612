export default {
    returnUrl(router) {
        if(router.currentRoute.path == '/' + router.currentRoute.params.lang) {
            return '/'
        } else {
            return router.currentRoute.path.replace('/'+ router.currentRoute.params.lang + '/','');
        }
    },

    changeLang(lang) {
        this.$i18n.locale = lang
        console.log(lang)
    }
};
