import gql from 'graphql-tag'

export const GET_BUILDINGS_QUERY = gql`
query ($slug:[String], $siteId: [QueryArgument]) {
  building: entries (section:"boligblok", slug: $slug, siteId: $siteId) {
  ... on boligblok_boligblok_Entry {
    id,
    title,
    blok_navn,
    uri,
    slug,
    extra_billede {
        url,
        title
    },
    extra_billede_title,
    facade {
        url,
        urlSmall: url @transform(width:200, height:133, mode:"crop"),
        urlMedium: url @transform(width:258, height:177, mode:"crop"),
        urlLarge: url @transform(width:720, height:540, mode:"crop"),
        title
    },
    facade_title,
    extra_information,
    lejligheds_typer,
    supplementaryBuildingText,
    streetandnum,
    buildingZipcode,
    city,
    building_latitude,
    building_longtitude,
    buildingRentType,
    buildingSize,
    buildingMedia(type: "mediafiles") {
        ... on buildingMedia_mediafiles_BlockType {
          mediatype,
          vimeoId,
          image {
            url @transform (width: 1200, height: 650, mode:"crop"),
            urlLarge: url @transform (width:1500, height:800, mode:"crop"),
            title
          }
        }
    },
    buildingType {
      ... on boligtyper_boligtyper_Entry {
          title
      }
    }
  }
  }
}`

export const GET_SPECIFIC_PROPERTY_QUERY = gql`
query ($slug:[String], $siteId: [QueryArgument]) {
  property: entries (section:"residential", slug: $slug, siteId: $siteId) {
  ... on residential_residential_Entry {
    title,
    rentalHeadline,
    apartment_images {
        url,
        title
    },
    bolignr,
    boligtyper {
      title
    }
    slug,
    rent,
    deposit,
    consumption,
    yearlyOperationCost,
    yearlyOperationCostPrSquareMeter,
    yearlyRentInclOpreationCost,
    yearlyRentInclOperationCostPrSquaremeter,
    yearlyRentPrSquareMeter,
    floorPlan {
        url @transform (width:1000, height: 740, mode:"crop"),
        title,
    }
    information(type: "information") {
      ... on information_information_BlockType {
        information_type,
        info_name,
        info_value,
        info_link,
        info_linktarget,
        info_linkrel,
        info_linkalt
      }
    },
    rentalMedia(type: "rentalMediaFiles") {
      ... on rentalMedia_rentalMediaFiles_BlockType {
        mediaType,
        vimeoId,
        coverImage: image {
            url @transform (width: 1200, height: 650, mode:"crop"),
            title
        }
        image {
            url @transform (width: 600, height: 450, mode:"crop"),
            urlLarge: url @transform (width:1000, height:750, mode:"crop")
            title
        },
        primary
      }
    },
    bolig_blok {
      ... on boligblok_boligblok_Entry {
          title,
          slug,
          city,
          facade {
              url,
              title
          }
      }
    },
    lejlighed_status,
    lejligheds_type,
    strelse,
    click_disabled,
    longtitude,
    latitude,
    propertyDescription,
    boligtyper {
      ... on boligtyper_boligtyper_Entry {
          title
      }
    },
    relatedRentals {
      ... on residential_residential_Entry {
          title,
          slug,
          lejligheds_type,
          lejlighed_status,
          strelse,
          apartment_images {
              url @transform(width:234, height:133, mode:"crop")
          }
      }
    },
  }
  }
}`

export const GET_PROPERTY_QUERY = gql`
query ($building:[QueryArgument], $siteId: [QueryArgument]) {
  property: entries (section:"residential", bolig_blok: $building, siteId: $siteId) {
  ... on residential_residential_Entry {
    title,
    apartment_images {
        url,
        urlMedium: url @transform(width:600, height:450, mode:"crop")
        urlSmall: url @transform(width:234, height:133, mode:"crop")
        urlLarge: url @transform(width:1000, height:750, mode:"crop")
        title
    },
    bolignr,
    boligtyper {
      title
    }
    slug,
    rent,
    consumption,
    information(type: "information") {
      ... on information_information_BlockType {
        information_type,
        info_type,
        info_name,
        info_value,
        info_link,
        info_linktarget,
        info_linkrel,
        info_linkalt
      }
    }
    bolig_blok {
      ... on boligblok_boligblok_Entry {
          title,
          slug,
          city,
          buildingZipcode,
          streetandnum,
          facade {
              url,
              urlSmall: url @transform(width:200, height:133, mode:"crop"),
              urlMedium: url @transform(width:258, height:177, mode:"crop"),
              urlLarge: url @transform(width:720, height:540, mode:"crop"),
              title
          }
      }
    },
    lejlighed_status,
    lejligheds_type,
    strelse,
    click_disabled,
    longtitude,
    latitude,
    rentalHeadline,
    boligtyper {
      ... on boligtyper_boligtyper_Entry {
          title
      }
    },
  }
  }
}`
