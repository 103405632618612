<script>
  export default {
    name: 'modal',
    props: ['image', 'show', 'text', 'headline'],
    methods: {
      close() {
        this.$emit('close');
      },
      replaceWithBr() {
          return this.text.replace(/\n/g, "<br />")
      }
  }
  };
</script>
<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal rental"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          id="modalTitle"
        >
          <slot name="header">
            <h2>{{ headline }}</h2>

            <button
              type="button"
              class="close"
              @click="close"
              aria-label="Close modal"
            >
              <i class="fa fa-times"></i>
            </button>
          </slot>
        </header>
        <section
          class="modal-body"
          id="modalDescription"
        >
          <div name="body">
            <img v-if="show === 'image'" :src="image.url" :alt="image.title">
            <p v-if="show === 'desc'" v-html="replaceWithBr()">{{text}}</p>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>
<style>
  .modal-backdrop {
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    position:absolute;
    top:300px;
    width: auto;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    text-align:left;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    border-bottom: 1px solid #eeeeee;
  color: #000;
  font-size: 14px;
  font-weight: 900;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    border: none;
    font-size: 20px;
    padding: 20px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }
</style>
