<template>
  <div>
    <section id="page-title">
      <div class="container clearfix">
	<h1>{{ contentrows.title }}</h1>
      </div>
    </section>
    <Contentrows v-bind:gmap="gmap"
                 v-bind:carousel="carousel"
                 v-bind:contentrows="contentrows"
                 v-bind:globalSet="globalSet"/>
  </div>
</template>

<script>
  import ContentpageContentrows from './ContentpageContentrows'
  import { GET_CONTENTPAGE_QUERY } from '../graphql/contentrowsQueries';
  import { siteId } from '../generalSettings.js'

  export default {
     name: 'Contentpage',
     props: ['globalSet'],
     components: {
        'Contentrows': ContentpageContentrows
     },
     data() {
       return {
           contentrows: [],
           gmap: [],
           carousel: []
       }
     },
     created() {
         this.fetchData()
     },
     watch: {
         $route () {
             this.contentrows = []
             this.gmap = []
             this.carousel = []
             this.fetchData()
         }
     },
     methods: {
        fetchData () {
           var slug = this.$helpers.returnUrl(this.$router).substring(this.$helpers.returnUrl(this.$router).lastIndexOf('/') + 1)
             this.$apollo.query({
                 query: GET_CONTENTPAGE_QUERY,
                 variables: {
                     "slug": slug,
                     siteId: this.$i18n.siteId

                 }
             }).then(function (response) {
                 if(response.data.contentrows[0]) {
                     this.contentrows = response.data.contentrows[0]
                 }
                 if(response.data.gmap[0]) {
                     this.gmap = response.data.gmap[0]
                 }
                 if(response.data.carousel[0].content_content_grid[0]) {
                     this.carousel = response.data.carousel[0].content_content_grid[0].image_carousel
                 }
              }.bind(this))
         }
     }
   }
</script>

<style>
</style>
