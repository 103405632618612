<template>
 <section id="slider" class="slider-parallax revoslider-wrap clearfix text-center">
   <div class="tp-banner-container">
     <div class="tp-banner" style="">
       <div v-if="loading" class="loading">
         Loading...
       </div>
       <div v-if="entries">
         <carousel :perPage=1 :navigationEnabled="false" :autoplay="true" :autoplayHoverPause="true" :paginationEnabled="false" :autoplayTimeout=15000 :loop="true">
           <slide v-for="entry in entries" v-bind:key="entry">
<div class="slotholder" v-if="entry.mediaType ==='image'">
             <div v-for="image in entry.slide_image" v-bind:key="image">
               <img v-lazy="image.url"  alt="video_typing_cover"  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat">
             </div>
             <div class="overlay" :style="'opacity:' + entry.overlayTransparency +'; background-color:'+ entry.overlayColor +';'"></div>
           </div>
           <div class="slotholder video" v-if="entry.mediaType ==='youtubeVideo'">
             <iframe v-if="entry.youtubeId" src="'https://www.youtube.com/embed/'+ entry.youtubeId + '?rel=0&controls=0&showinfo=0&autoplay=1&loop=1&playlist=&mute=1'" frameborder="0" allowfullscreen></iframe>
             <div class="overlay" :style="'opacity:' + entry.overlayTransparency +'; background-color:'+ entry.overlayColor +';'"></div>
           </div>
           <div class="slotholder video" v-if="entry.mediaType ==='vimeoVideo'">
             <iframe v-if="entry.vimeoId" :src="'https://player.vimeo.com/video/'+ entry.vimeoId +'?quality=auto&autoplay=1&loop=1&autopause=0&muted=1'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
             <div class="overlay" :style="'opacity:' + entry.overlayTransparency +'; background-color:'+ entry.overlayColor +';'"></div>
           </div>
           <div v-if="entry.headline" class="hidden-sm hidden-xs headline" v-html="entry.headline"></div>
           <div v-if="entry.sub_headline" class="hidden-sm hidden-xs subheadline" v-html="entry.sub_headline"></div>
           <div v-if="entry.headlineResponsive" class="hidden-md hidden-lg headlineResponsive" v-html="entry.headlineResponsive"></div>
           <div v-if="entry.sub_headlineResponsive" class="hidden-md hidden-lg subheadlineResponsive" v-html="entry.sub_headlineResponsive"></div>

           <div class="buttons">
             <router-link v-if="entry.button1_link" :to="'/' + $i18n.locale + entry.button1_link" :target="entry.link_1_destination" class="button slide-button button-white button-light button-large button-rounded button-border tright nomargin button1">
               <span>{{ entry.button1_text }}</span>
               <i class="icon-angle-right"></i>
             </router-link>
             <router-link v-if="entry.button2_link" :to="'/' + $i18n.locale + entry.button2_link" :target="entry.link_2_destination" class="button button-large button-rounded slide-button tright nomargin button2" style="color: #fff;">
               <span>{{ entry.button2_text }}</span>
               <i class="icon-angle-right"></i>
             </router-link>
           </div>
           </slide>
         </carousel>
       </div>
     </div>
   </div>
 </section>
 </template>

<script>
import { GET_SLIDER_QUERY } from '../graphql/sliderQueries';
import { Carousel, Slide } from 'vue-carousel';
                                     
export default {
  name: 'slider',
    data() {
    return {
        loading: false,
        entries: []
    }
   },
   components: {
      Carousel,
      Slide,
   },
   created() {
      this.data = this.fetchData()
   },
   methods: {
      fetchData () {
        this.loading = false
        this.$apollo.query({
                 query: GET_SLIDER_QUERY,
                 variables: {
                     siteId: this.$i18n.siteId
                 }
        }).then(function (response) {
          if(response.data.entries) {
              this.entries = response.data.entries;
          }
        }.bind(this));
      },
    },
}
</script>

<style>
</style>
