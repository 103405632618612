<template>
  <div>
    <form v-on:submit="onSubmit">
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-firstName" class="required">Name</label>
          <input class="form-control" v-model="firstName" name="firstName" type="text" id="form-input-firstName" placeholder="Enter name">
          <ul v-if="getErrors('firstName').length" class="errors">
            <li v-for="error in getErrors('firstName')" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-Postnr-by" class="required">City or Zipcode</label>
          <input class="form-control" v-model="postnrby" name="Postnr-by" type="text" id="form-input-Postnr-by" placeholder="Enter City or zipcode">
          <ul v-if="getErrors('Postnr-by').length" class="errors">
            <li v-for="error in getErrors('Postnr-by')" :key="error">
              {{ error }}
            </li>
          </ul>          
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-email" class="required">Email</label>
          <input class="form-control" v-model="email" name="email" type="email" id="form-input-email" placeholder="Enter email">
          <ul v-if="getErrors('email').length" class="errors">
            <li v-for="error in getErrors('email')" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-cellPhone" class="required">Cell Phone</label>
          <input class="form-control" v-model="cellPhone" name="cellPhone" type="text" id="form-input-cellPhone" placeholder="Enter Cell Phone">
          <ul v-if="getErrors('cellPhone').length" class="errors">
            <li v-for="error in getErrors('cellPhone')" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-message" class="">Message</label>
          <textarea class="form-control" v-model="message" name="message" id="form-input-message" rows="5" placeholder="Enter your message"></textarea>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-nuvrendeBoligform" class="required">Current residential type?</label>
          <input class="form-control" v-model="nuvrendeBoligform" name="nuvrendeBoligform" type="text" id="form-input-nuvrendeBoligform">
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-boligtype" class="required">Residential type</label>
          <select class="form-control" v-model="boligtype" name="boligtype" id="form-input-boligtype">
            <option value="Lejlighed type A">Apartment type A</option><option value="Lejlighed type B">Apartment type B</option><option value="Lejlighed type C">Apartment type C</option><option value="Penthouse type A">Penthouse type A</option><option value="Penthouse type B">Penthouse type B</option><option value="Penthouse type C">Penthouse type C</option><option value="Lejlighed type 25">Lejlighed type 25</option>
          </select><div class="help-block">Select the type of apartment you are interested in</div>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-boligtype">Where have you heard of Bernhardshave ?</label>
          <select class="form-control" v-model="hvorHarDuHrtOmProjektet" name="hvorHarDuHrtOmProjektet" id="form-input-hvorHarDuHrtOmProjektet">
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Online search result">Online search result</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <div>It is non-binding and you will only receive emails in relation to Bernhardshave in Frederiksberg. We do not forward your contact data to anyone other than real estate broker EDC Project Poul Erik Beck</div>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12 submit-align-left">
          <button class="" type="submit" name="form_page_submit">Contact/submit to waiting list</button>
        </div>
      </div>
    </form>

      <div class="vld-parent">
    <Loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="fullPage"
             :color="loadingColor"
             ></Loading>
  </div>
  </div>
</template>

<script>
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'
  import { backendUrl, formHashEn } from '../generalSettings'
  export default {
  name: 'Contact form',
  props: ['gaevent'],
  delimiters: ['${', '}'],
  data() {
    return {
      isLoading:false,
      fullPage: true,
      loader: 'dots',
      loadingColor: '#f89520',
      loadingOpacity: 0.5,
      firstName: '',
      email: '',
      postnrby: '',
      cellPhone: '',
      message: '',
      nuvrendeBoligform: '',
      boligtype: '',
      hvorHarDuHrtOmProjektet: '',
      errormessage: 'Please fill in this field',
      errors: [],
   }
  },
  components: {
      Loading
  },
  methods: {
      getErrors: function (property) {
      const errors = [];
      if (!this.errors.length) {
          return errors;
        }
        for (let i = 0; i < this.errors.length; i++) {
          if (this.errors[i].key === property) {
            errors.push(this.errors[i].message);
          }
        }
        return errors;
      },
      validate: function (property) {
        if (!property) {
          this.errors = [];
        } else {
          this.errors = this.errors.filter((item) => item.key !== property);
        }
        if ((!property || property === 'firstName') && !this.firstName) {
          this.errors.push({ key: 'firstName', message: this.errormessage });
        }
        if ((!property || property === 'email') && !this.email) {
          this.errors.push({ key: 'email', message: this.errormessage });
        }
        if ((!property || property === 'postnrby') && !this.postnrby) {
          this.errors.push({ key: 'Postnr-by', message: this.errormessage });
        }
        if ((!property || property === 'cellPhone') && !this.cellPhone) {
          this.errors.push({ key: 'cellPhone', message: this.errormessage });
        }
      },
      onSubmit: function (evt) {
        this.validate();
        if (this.errors.length) {
         evt.stopPropagation();
         evt.preventDefault();
          return false;
        }
        this.isLoading = true;
        const form = evt.target;
        const data = new FormData(form);
        const request = new XMLHttpRequest();

        // Add Honeypot field
        data.append('freeform_form_handle', '');

        // Add action which handles freeform validation
        data.append('action', 'freeform/api/form');

        // Add the form's hash, so that it gets recognized by the form service
        data.append('formHash', formHashEn);
        // Append the data
        data.append('firstName', this.firstName);
        data.append('Postnr-by', this.postnrby);
        data.append('email', this.email);
        data.append('cellphone', this.cellphone);
        data.append('message', this.message);
        data.append('nuvrendeBoligform', this.nuvrendeBoligform);
        data.append('boligtype', this.boligtype);
        data.append('hvorHarDuHrtOmProjektet', this.hvorHarDuHrtOmProjektet);
    
        // Create a request
        request.open('POST', backendUrl, true);
        request.setRequestHeader("Cache-Control", "no-cache");

        // Add the AJAX headers specific to Craft CMS
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest");

        // Attach the response handler callback
        request.onload = () => {

        this.isLoading = false

        this.$gtm.trackEvent({
            event: null,
            category: 'Ventelist',
            action: 'Tilmeld',
            label: this.gaevent,
            value: 1000
        });

        if (request.status === 200) {
            const response = JSON.parse(request.response);
            const { success, finished, errors, formErrors } = response
            if (success && finished) {
              // Reset the form so that the user may enter fresh information
              form.reset();
              window.location.href = response.returnUrl
            } else if (errors || formErrors) {
              alert('error');
            }
          }
        };

        // Send the request
        request.send(data);

        evt.stopPropagation();
        evt.preventDefault();
        return false;
      },
    },
  }

</script>

<style>
</style>
