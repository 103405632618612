<template>
  <div>
    <section id="page-title">
      <div class="container clearfix">
        <h1>{{ $t('Standbylist') }}</h1>
      </div>
    </section>
    <section id="content">
      <div class="content-wrap">
        <div class="container clearfix topmargin">
          <StandbylistForm />
        </div>
      </div>
    </section>        
  </div>
</template>

<script>
  import StandbylistForm from './StandbylistForm'
  
  export default {
  name: 'Standbylist',
  components: {
      'StandbylistForm': StandbylistForm
  },
  data () {
      return {
          saveEntry: {
              title: '',
              slug: '',
              fields: {
              standbylistBudget: '',
              standbylistCompanyname: '',
              standbylistContactperson: '',
              standbylistEmail: '',
              standbylistPhone: '',
              },
              action: 'guest-entries/save',
              sectionUid: 'b143cdee-fa97-472d-bf83-3dac4bdb12f7',
              enabled: 1
           },
           errors: null,
           message: null,
      }
  },
  computed: {
      csrfName () {
          return window.csrfTokenName
      },
      csrfToken () {
          return window.csrfTokenValue
      }
  },
  methods: {
      doLogin () {
          this.saveEntry['title'] = this.$refs['standbylistCompanyname'].value + ' '
                                  + this.$refs['standbylistContactperson'].value
          this.saveEntry['slug'] = Math.floor(Math.random() * 9999999)
          let data = this.saveEntry

          this.$http.post('/', data)
          .then(function (response) {
              if (response.body.success) {
                     this.$router.push('/tak-for-tilmelding-til-venteliste')
              }
              if (response.body.error) {
                  this.errors = response.body.error
              }
          })
      }
  }
}
</script>

<style>
</style>
