var hostname = window.location.hostname
var site = 0;
var gtmid = 0;

if(hostname == 'localhost' ||
   hostname == 'www.industribo.dk' ||
   hostname == 'industribo.dk') {
   site = 1
   gtmid = 'GTM-NS9BZ443';
} else {
   site = 1
   gtmid = 'GTM-NS9BZ443'; 
}

export var siteId = site;
export var backendUrl = 'https://industribo.dk';
export var formHash = 'vW54XMbrG-lEkYzdV7Z-QYTevS4moama5FHx7rioJqVvuKFpvK3x7vuGdCH5';
export var rentalContactFormHash = 'qDE45WoLZ-bkBAZrAon-6O2zDZqWJihYxJVVRiBgBmkyhlI9VKRtYpn0wuiA';
export var apiKey = '9hl8X35UgXM_fwO4_h3zfFMO7hd505KX';
export var gtmId = gtmid;
