<template>
  <div class="col_full">         
    <form id="contact" v-on:submit="onSubmit" enctype="multipart/form-data">

      <div v-if="submiterror"><p class="alert alert-danger">{{ submiterror }}</p></div>
      <div><p class="formerror"><b>Felter markeret med rødt mangler at blive udfyldt</b></p></div>
      <div class="form-group" v-if="!rental">
        <label>Lejemål<span class="required_item">*</span></label>
        <select class="form-control"
                name="propertyname"
                value="Vælg lejemål"
                v-model="propertyname">
          <option v-for="(propertyrow) in properties"
                  :key="propertyrow"
                  :value="propertyrow.bolig_blok[0].title + ' ' + propertyrow.title + ' ( ' + propertyrow.bolignr + ' )'">{{ propertyrow.bolig_blok[0].title }} {{ propertyrow.title }} ( {{ propertyrow.bolignr }} )</option>
        </select>
        <ul v-if="getErrors('propertyname').length" class="errors">
          <li v-for="error in getErrors('propertyname')" :key="error">
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="form-group">
        <label>Virksomhedsnavn</label>
        <input type="text" class="form-control" v-model="companyName" name="companyName" id="form-id-companyName" placeholder="Indtast firmanavn">
        <ul v-if="getErrors('companyName').length" class="errors">
          <li v-for="error in getErrors('companyName')" :key="error">
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="form-group">
        <Label>Kontaktperson</label>
        <input type="text" class="form-control" v-model="contactperson" name="contactperson" id="form-id-contactperson" placeholder="Indtast navn">
        <ul v-if="getErrors('contactperson').length" class="errors">
          <li v-for="error in getErrors('contactperson')" :key="error">
            {{ error }}
          </li>
        </ul>

      </div>
      <div class="form-group">
        <label>Kontakt telefon</label>
        <input type="text" class="form-control" v-model="phone" name="phone" id="form-input-phone" placeholder="Indtast telefonnummer">
        <ul v-if="getErrors('phone').length" class="errors">
          <li v-for="error in getErrors('phone')" :key="error">
            {{ error }}
          </li>
        </ul>
      </div>    
      <div class="form-group">
        <label>Email</label>
        <input type="email" class="form-control" v-model="email" name="email" id="form-input-email" placeholder="Indtast email">
        <ul v-if="getErrors('email').length" class="errors">
          <li v-for="error in getErrors('email')" :key="error">
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="form-group">
        <label>Vedhæft en fil ( Kun billeder og pdf filer - Max størrelse: 2 MB)</label>
        <input type="file" class="form-control" ref="attachments">
      </div>
      <div class="form-group">
        <label>Besked</label>
        <textarea rows="10" class="form-control" v-model="message" name="message" id="form-input-message" placeholder="Indtast besked"></textarea>
        <ul v-if="getErrors('message').length" class="errors">
          <li v-for="error in getErrors('message')" :key="error">
            {{ error }}
          </li>
        </ul>
      </div>
      <div>
        <button name="submit" type="submit" value="Submit" class="button button-border button-rounded button-large">Send</button>
        <p><br>
        </p>
      </div>
      <div class="clear"></div>
    </form>
    <div class="vld-parent">
      <Loading :active.sync="isLoading"
               :can-cancel="true"
               :is-full-page="fullPage"
               :color="loadingColor"
               ></Loading>
    </div>

  </div>
</template>

<script>
  import { GET_PROPERTY_QUERY } from '../graphql/buildingQueires'
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'
  import { backendUrl } from '../generalSettings'

  export default {
  name: 'StandbylistForm',
  props: ['rental', 'rentalTitle'],
    components: {
      Loading
  },
  data () {
  return {
      isLoading:false,
      fullPage: true,
      loader: 'dots',
      loadingColor: '#f89520',
      loadingOpacity: 0.5,
      properties: [],
      propertyname: '',
      companyName: '',
      contactperson: '',
      phone: '',
      message: '',
      submiterror: '',
      errormessage: 'Feltet skal udfyldes',
      errors: [],
      }
  },
  created() {
      this.getJson()
      this.fetchProperties()      
  },
  methods: {
      getErrors: function (property) {
      const errors = [];
      if (!this.errors.length) {
          return errors;
        }
        for (let i = 0; i < this.errors.length; i++) {
          if (this.errors[i].key === property) {
            errors.push(this.errors[i].message);
          }
        }
        return errors;
      },
      getJson: function() {
           var requestUrl = backendUrl + '/rentalcontactda.html'
           if(this.$i18n.siteId == 1) {
               requestUrl = backendUrl + '/rentalcontactda.html'
           } else if(this.$i18n.siteId == 4) {
               requestUrl = backendUrl + '/rentalcontacten.html'
           } else if(this.$i18n.siteId == 3) {
               requestUrl = backendUrl + '/rentalcontactde.html'
           }                            
           fetch(requestUrl)
               .then(r => r.json())
               .then(json => { this.formSettings = json; })
      },
      validate: function (property) {
        if (!property) {
          this.errors = [];
        } else {
          this.errors = this.errors.filter((item) => item.key !== property);
        }
        if ((!property|| property === 'propertyname') && !this.propertyname) {
          this.errors.push({ key: 'propertyname', message: this.errormessage });
        }
        if ((!property || property === 'companyName') && !this.companyName) {
          this.errors.push({ key: 'companyName', message: this.errormessage });
        }
        if ((!property || property === 'contactperson') && !this.contactperson) {
          this.errors.push({ key: 'contactperson', message: this.errormessage });
        }
        if ((!property || property === 'phone') && !this.phone) {
          this.errors.push({ key: 'phone', message: this.errormessage });
        }
        if ((!property || property === 'email') && !this.email) {
          this.errors.push({ key: 'email', message: this.errormessage });
        }
        if ((!property || property === 'message') && !this.message) {
          this.errors.push({ key: 'message', message: this.errormessage });
        }
      },
      onSubmit: function (evt) {
        this.validate();
        if (this.errors.length) {
         evt.stopPropagation();
         evt.preventDefault();
          return false;
        }
        this.isLoading = true;
        const form = evt.target;
        const data = new FormData(form);
        const request = new XMLHttpRequest();

        // Add Honeypot field
        data.append('freeform_form_handle', '');

        // Add action which handles freeform validation
        data.append('action', 'freeform/submit');
        data.append('freeform-action', 'submit');

        // Add the form's hash, so that it gets recognized by the form service
        data.append('formHash', this.formSettings.hash);
        data.append(this.formSettings.csrf.name, this.formSettings.csrf.value);
        data.append('freeform_payload', this.formSettings.payload);

        data.append('file[]', this.$refs.attachments.files[0]);
    
        // Create a request
        request.open('POST', backendUrl, true);
        request.setRequestHeader("Cache-Control", "no-cache");

        // Add the AJAX headers specific to Craft CMS
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest");

        // Attach the response handler callback
        request.onload = () => {

        this.isLoading = false

        if (request.status === 200) {
            const response = JSON.parse(request.response);
            const { success, finished, errors, formErrors } = response
            if (success && finished) {
              // Reset the form so that the user may enter fresh information
              form.reset();
              this.submiterror = ''
              window.location.href = response.returnUrl
            } else if (errors || formErrors) {
            if(errors['file']) {
                this.submiterror = this.$i18n.t('Max filesize is 2MB')
            } else {
                this.submiterror = this.$i18n.t('An error occurred. Please check that everything is filled out correctly and try again')
            }
            }
          }
        };

        // Send the request
        request.send(data);

        evt.stopPropagation();
        evt.preventDefault();
        return false;
      },

     fetchProperties ($building) {
         this.$apollo.query({
             query: GET_PROPERTY_QUERY,
                 variables: {
                     building: $building,
                     siteId: this.$i18n.siteId
                 }
             }).then(function (response) {
                 if(response.data.property.length > 0) {
                     this.properties = response.data.property
                 }
             }.bind(this))
    },
  }
}
</script>

<style>
</style>
