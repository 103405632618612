 <template>
   <footer id="footer" class="dark">			
     <div id="copyrights" style="padding: 20px 0;">
       <div class="container clearfix">
         <div class="row hidden-sm hidden-xs" v-for="global in globalSet" v-bind:key="global">
           <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3" style="">
             <h5>{{ $t('Footer headline available rentals') }}</h5>
             <ul>
               <li v-for="rental in rentals" :key="rental">
                 <router-link :to="'/' + $i18n.locale + $t('tenacypath') + '/' + rental.slug">
                   {{ rental.title }}</router-link></li>
             </ul>
             <router-link :to="'/' + $i18n.locale + $t('rentalpath')">
               <input type="button" :value="$t('See more rentals')" class="button button-border button-rounded button-large">
             </router-link>
           </div>
           <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3" style="" v-html="global.footerColumn1">
           </div>
           <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3" style="" v-html="global.footerColumn2"></div>
           <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 tright" style="">
             <div class="row">
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                 <a href="/" :alt="global.footer_logo_alt" target="_blandk" v-for="footer_logo in global.footer_logo" v-bind:key="footer_logo">
                   <img :src="footer_logo.url" :alt="global.footer_logo_alt" class="footer-logo float-right">
                 </a>
               </div>
               <div class="contactinfo col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-html="global.footerColumn3"></div>
               <div>
               </div>
             </div>
           </div>
         </div>

         <div class="row hidden-lg hidden-md" v-for="global in globalSet" v-bind:key="global">
           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
             <div class="mb-30" v-html="global.footerColumn2"></div>
             <div class="" v-html="global.footerColumn1"></div>
           </div>
           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 tright" style="">
             <div class="row pull-right">
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                 <a href="/" :alt="global.footer_logo_alt" target="_blandk" class="pull-right" v-for="footer_logo in global.footer_logo" v-bind:key="footer_logo">
                   <img :src="footer_logo.url" :alt="global.footer_logo_alt" class="footer-logo float-right">
                 </a>
               </div>
               <div class="contactinfo col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-html="global.footerColumn3"></div>
               <div>
               </div>
             </div>
           </div>
         </div>
         <div class="row hidden-lg hidden-md" v-for="global in globalSet" v-bind:key="global">
           <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 mb-30" style=" margin-top:20px;">
             <h5>{{ $t('Footer headline available rentals') }}</h5>
             <ul>
               <li v-for="rental in rentals" :key="rental">
                 <router-link :to="'/' + $i18n.locale + $t('tenacypath') + '/' + rental.slug">
{{ rental.title }} - {{ rental.title }}</router-link></li>
             </ul>
             <router-link :to="'/' + $i18n.locale + $t('rentalpath')">
               <input type="button" :value="$t('See more rentals')" class="button button-border button-rounded button-large">
             </router-link>
           </div>           
           
         </div>

       </div>
     </div>
   </footer>
 </template>

<script>
  import { GET_PROPERTY_QUERY } from '../graphql/buildingQueires'
   
  export default {
    name: 'Footer',
    props: ['globalSet'],
    data() {
        return {
            rentals: []
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData () {
            this.$apollo.query({
                query: GET_PROPERTY_QUERY,
                    variables: {
                        siteId: this.$i18n.siteId
                    }
                }).then(function (response) {
                    if(response.data.property.length > 0) {
                        this.rentals = this.getMultipleRandom(response.data.property,5)
                    }
                 }.bind(this))
        },
        getMultipleRandom(arr, num) {
            const shuffled = [...arr].sort(() => 0.5 - Math.random());
            return shuffled.slice(0, num);
        }
    }
  }
</script>

<style>
</style>
