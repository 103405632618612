<template>
<div>
  <div id="logo" v-for="global in globalSet" :key="global" style="margin-bottom:20px;">

      <div class="standard-logo" :alt="global.logo_alt" v-for="logo in global.big_logo" v-bind:key="logo">
        <img :src="logo.url" :alt="global.logo_alt">
      </div>
  </div>

  <h1> {{rental.rentalHeadline}} </h1>
  
  <div v-for="(mediaFile) in rentalMedia" :key="mediaFile" style="margin-bottom:20px;">
    <img v-if="mediaFile.mediaType == 'image'" :src="mediaFile.image" style="width:700px;">
    <p>{{ mediaFile.title }}</p>
  </div>
</div>
</template>
<script>
 
export default {
    props: ['rental','rentalMedia','globalSet'],
}
</script>
<style>
  </style>
