import Vue from 'vue'
import App from './App.vue'
import VueGtm from '@gtm-support/vue2-gtm'
import VueRouter from 'vue-router'
import { apolloProvider } from './graphql/apollo';
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from 'vue2-google-maps/src/components/cluster' 
import vueScrollto from 'vue-scrollto'
import VueSwal from 'vue-swal'
import helpers from './Helpers'
import VueI18n from 'vue-i18n'

import VueLazyload from 'vue-lazyload'
import VueSocialSharing from 'vue-social-sharing'
import VueHtmlToPaper from 'vue-html-to-paper';
import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin'

import Page404 from './components/404'
import Frontpage from './components/Frontpage'
import Contentpage from './components/Contentpage'
import Contactpage from './components/Contactpage'
import Buildinginfo from './components/Buildinginfo'
import Buildingoverview from './components/Buildingoverview'
import Residentlogin from './components/Residentlogin'
import Residentinfo from './components/Residentinfo'
import Standbylist from './components/Standbylist'
import Residentcontact from './components/Residentcontact'
import Rental from './components/Rental'
import CookieConsentPage from './components/CookieConsentPage'

import { gtmId } from './generalSettings.js'

import VueResource from 'vue-resource'
Vue.use(VueResource)
Vue.http.options.emulateJSON = true
const http=Vue.http
export default http

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const authorisedLanguages = ['en','da','de'];
const locale = authorisedLanguages.includes(navigator.language) ? navigator.language : 'da';

const i18n = new VueI18n({
    locale,
    fallbackLocale: 'da', // By default, the page is translated into english
    messages: loadLocaleMessages(),
});

Vue.use(VueGtm, {
    id: 'GTM-NS9BZ443',
    source: 'https://gtm.industribo.dk/gtm.js',
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router
});

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.use(VueHtmlToPaper, options);

Vue.use(VueSocialSharing)

Vue.use(VueRouter)
Vue.use(vueScrollto, {
    container: "body",
    offset: -140,
})
Vue.use(VueSwal)

Vue.use(VueLazyload, {
    preLoad: 1,
    attempt: 2,

})

const routes = [
    { path: '*', component: Page404, props:true },
    { path: '/:lang', name: 'frontpage', component: Frontpage, props:true, },

    /* This is language dependent */
    { name: 'udlejning',
      path: '/:lang/udlejning',
      component: Buildingoverview,
      props:true,
    },
    {
        path: '/:lang/ejendom/:building',
        props:true,
        component: Buildinginfo,
    },
    { path: '/:lang/lejemoel/:rental', component: Rental, props:true },
    { path: '/:lang/cookiepolitik', component: CookieConsentPage, props:true },
    { path: '/:lang/lejerinformation', component: Residentinfo, props:true },
    { path: '/:lang/lejerlogin', component: Residentlogin, props: true },
    { path: '/:lang/lejerkontakt', component: Residentcontact, props: true },
    { path: '/:lang/interesseliste', component: Standbylist, props: true },
    { path: '/:lang/kontakt', component: Contactpage, props:true },
    
    { name: 'udlejning',
      path: '/:lang/rental',
      component: Buildingoverview,
      props:true,
    },
    {
        path: '/:lang/property/:building',
        props:true,
        component: Buildinginfo,
    },
    { path: '/:lang/tenancy/:rental', component: Rental, props:true },
    { path: '/:lang/cookie-policy-for-industribo', component: CookieConsentPage, props:true },
    { path: '/:lang/tenantinformation', component: Residentinfo, props:true },
    { path: '/:lang/tenantlogin', component: Residentlogin, props: true },
    { path: '/:lang/tenantcontact', component: Residentcontact, props: true },
    { path: '/:lang/standbylist', component: Standbylist, props: true },
    { path: '/:lang/contact', component: Contactpage, props:true },

    { name: 'udlejning',
      path: '/:lang/vermietung',
      component: Buildingoverview,
      props:true,
    },
    {
        path: '/:lang/immobilie/:building',
        props:true,
        component: Buildinginfo,
    },    
    { path: '/:lang/mietvertrag/:rental', component: Rental, props:true },
    { path: '/:lang/cookierichtlinie', component: CookieConsentPage, props:true },
    { path: '/:lang/informationen', component: Residentinfo, props:true },
    { path: '/:lang/mieteranmeldung', component: Residentlogin, props: true },
    { path: '/:lang/mieterkontakt', component: Residentcontact, props: true },
    { path: '/:lang/interessenliste', component: Standbylist, props: true },
    { path: '/:lang/cookie-richtlinie-fur-industribo', component: CookieConsentPage, props: true},
    { path: '/:lang/Kontakt', component: Contactpage, props:true },
    
    
    /* End of language dependent url */

    /* This is language independent */
    { path: '/:lang/*', component: Contentpage, props: true },
    /* End of language independent urls */
]

const router = new VueRouter({
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    mode: 'history'
})

router.beforeEach((to, from, next) => {
  const locale = to.params.lang; // Retrieve the current locale set in the URL

  // Check if the locale the user is trying to access is authorised.
  // In a larger application that supports lots of languages, you may want to store
  // all the locales in a separate array
  if (!['en', 'da', 'de'].includes(locale)) {
     return next(i18n.locale);
  }
  // Changing the language from the URl (either manually or with a link) is possible this way
    if(locale == 'da') {
        i18n.siteId = 1;        
    } else if(locale == 'en') {
        i18n.siteId = 4;
    } else if(locale == 'de') {
        i18n.siteId = 3;
    }

  Vue.use(VueCookieBot, {
    cookieBotID: 'bee05fe7-e531-4d4d-950d-841c4c63a57d',
    defaultLocale: i18n.locale
  })

  i18n.locale = locale;
  return next();
});


Vue.use(VueGtm, {
  id: gtmId,
  defer: false,
  compatibility: false,
  nonce: '2726c7f26c',
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  ignoredViews: ['homepage'],
  trackOnNextTick: false,
});

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA6abmDciKj52AItJKsYFgRAb_iseMMVbk",
    libraries: "places" // necessary for places input
  }
});
Vue.component('GmapCluster', GmapCluster) 

const plugin = {
    install() {
        Vue.helpers = helpers
        Vue.prototype.$helpers = helpers
    }
}
Vue.use(plugin);

new Vue({
    i18n,
    router,
    apolloProvider,
    render: h => h(App)
}).$mount('#app')
