import gql from 'graphql-tag';

export const GET_ALL_GLOBALS = gql`
query($siteId: [QueryArgument]) {
  globalSets(siteId: $siteId) {
  ... on site_config_GlobalSet {
  	backgroundimage404page {
  	  url
  	},
    callToActionLinkOnFrontpage,
    callToActionTextFrontpage,
    favicon {
      url
    },
    footerColumn1,
    footerColumn2,
    footerColumn3,
    footer_logo {
      url
    },
    footer_logo_alt,
    gmapHeight,
    small_logo {
      url
    },
    logo_alt,
    popupCallToAction,
    popupCallToActionImage {
      url,
      title
    },
    popupCallToActionForm {
      id
    },
    popupCallToActionHeadline,
    popupCallToActionText,
    defaultGmapLat,
    defaultGmapLng,
    big_logo {
      url
    },
    tellafriend_message,
    tellafriend_subject,
    tellafriend_fromemail,
    tellafriend_fromtext
  }
  }
}`

export const GET_GENERAL_GLOBALS = gql`
query($siteId: [QueryArgument]) {
  globalSets(siteId: $siteId) {
  ... on site_config_GlobalSet {
    favicon {
      url
    },
    footerColumn1,
    footerColumn2,
    footer_logo {
      url
    },
    footer_logo_alt,
    small_logo {
      url
    },
    logo_alt,
    popupCallToAction,
    popupCallToActionImage {
      url
    },
    popupCallToActionForm {
      id
    }
    popupCallToActionHeadline,
    popupCallToActionText,
    big_logo {
      url
    }
  }
  }
}`
