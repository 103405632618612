<template>
<div>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
        <span class="searchlabel">{{ $t('Find property') }}</span>
      </div>
    </div>
  </div>
  <div class="searchinput">
    <div class="container" v-if="loadingdone">
      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <label>{{ $t('Choose city') }}</label>
          <div class="select">
            <select name="city" v-model="selectedCity">
              <option :value="null">{{ $t('Choose') }}</option>
              <option v-for="city in sortFilters.cities"
                      :key="city"
                      :value="city">
                {{ city }}
              </option>
            </select>
            <span class="focus"></span>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <label>Type</label>
          <div class="select">
            <select id="renttype" name="renttype" v-model="selectedRenttype">
              <option :value="null" selected>{{ $t('Choose') }}</option>
              <option v-for="renttype in sortFilters.renttypes"
                      :key="renttype"
                      :value="renttype">
                {{ renttype }}
              </option>
            </select>
            <span class="focus"></span>
          </div>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <router-link :to="{ name: 'udlejning', params: { selectedCity: selectedCity, selectedRenttype: selectedRenttype }}">
            <input type="button" :value="$t('Find property')" class="button button-border button-rounded button-large float-right">
          </router-link>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
  import { GET_PROPERTY_QUERY } from '../graphql/buildingQueires'

  export default {
    name: 'Searchproperty',
    props: ['globalSet'],
    data() {
        return {
            rentals: [],
            sortFilters: [],
            selectedCity: null,
            selectedRenttype: null,
            loadingdone: 0
        }
    },
    created() {
        this.fetchRentalsData()
    },
    methods: {
         fetchRentalsData: function() {
             this.$apollo.query({
                 query: GET_PROPERTY_QUERY,
                 variables: {
                     siteId: this.$i18n.siteId
                 }
              }).then(function (response) {
                  if(response.data.property.length > 0) {
                      this.rentals = response.data.property
                  }
                  this.buildSortData()
                  this.loadingdone = 1;
                }.bind(this))
         },
         buildSortData () {
             this.sortFilters.renttypes = []
             this.sortFilters.cities = []

             for(let i=0; i < this.rentals.length; i++) {
                 if(this.rentals[i].bolig_blok.length > 0) {
                 if(this.rentals[i].boligtyper[0].title.length &&
                     this.sortFilters.renttypes.indexOf(this.rentals[i].boligtyper[0].title) == -1) {
                     this.sortFilters.renttypes.push(this.rentals[i].boligtyper[0].title)
                 }
                 if(this.rentals[i].bolig_blok[0].city.length &&
                     this.sortFilters.cities.indexOf(this.rentals[i].bolig_blok[0].city) == -1) {
                     this.sortFilters.cities.push(this.rentals[i].bolig_blok[0].city)
                 }
                 }
              }               
         }
    }
   }
</script>
