<template>
  <div>
    <section id="page-title">
      <div class="container clearfix">
        <h1>Lejer kontakt</h1>
      </div>
    </section>
    <section id="content">
      <div class="content-wrap">
        <div class="container clearfix topmargin">
          <ResidentcontactForm />
        </div>
      </div>
    </section>        
  </div>
</template>

<script>
  import ResidentcontactForm from './ResidentcontactForm'

  export default {
  name: 'Residentcontact',
  components: {
      'ResidentcontactForm': ResidentcontactForm
  }
}
</script>

<style>
</style>
